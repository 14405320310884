import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { valueContent } from '../utils/constants'; 
import { Title } from './ServicesSection';
import { Subtitle } from './AboutUsSection';

// Styled Components
const StyledContainer = styled(Container)`
  text-align: center;
  padding: 50px 0;
`;

const StyledCard = styled(Card)`
//   width: 393px;
    height: 440px;
    gap: 20px;
    border-radius: 20px;
    margin-bottom: 30px;
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.1);

  img {
    border-radius: 8px;
  }

  .card-body {
    padding: 20px;
  }
`;

const CardTitle = styled.h4`
  font-weight: 700;
  color: #003c5f;
`;

const CardText = styled.p`
  font-size: 1rem;
  color: #6c757d;
`;

const ValueSection = () => {
  return (
    <StyledContainer>
        <Subtitle className='text-center'>Our Value</Subtitle>
        <Title>Driving Insurance with Integrity</Title>
      <Row>
        {valueContent.values.map((value, index) => (
          <Col key={index} md={4}>
            <StyledCard>
              <Card.Img variant="top" src={value.image} alt={value.title} />
              <Card.Body>
                <CardTitle>{value.title}</CardTitle>
                <CardText>{value.description}</CardText>
              </Card.Body>
            </StyledCard>
          </Col>
        ))}
      </Row>
    </StyledContainer>
  );
};

export default ValueSection;
