import React from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { FaPhoneAlt, FaMapMarkerAlt, FaEnvelope } from 'react-icons/fa';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {Subtitle} from './AboutUsSection';
import { Title } from './ServicesSection';

export const IconContainer = styled.li`
  display: flex;
  margin: auto;
  align-items: center;
  padding: 10px 0;

  .icon {
    background-color: #EF5226;
    color: white;
    padding: 1vw;
    height: 60px;
    border-radius: 12%;
    margin-right: 15px;
  }

  @media screen and (max-width: 992px) {
    .icon {
      padding: 5vw;
    }
  }

  .text-container {
    display: flex;
    flex-direction: column;
  }

  .label {
    font-weight: bold;
  }

  .details {
    margin-top: 4px;
    font-size: 14px;
  }
`;

export const StyledContainer = styled(Container)`
  padding: 40px;
  border-radius: 10px;
`;

export const StyledForm = styled(Form)`
  background-color: #FCE2DB;
  padding: 10%;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

export  const StyledFormGroup = styled(Form.Group)`
  margin-bottom: 15px;
`;

export const StyledButton = styled(Button)`
  background-color: #EF5226;
  border: none;
  
  &:hover {
    background-color: #d14620;
  }

  &:focus,
  &:active {
    background-color: #EF5226;
    border: none;
    box-shadow: none;
    outline: none;
  }

  &:focus:not(:focus-visible),
  &:active:not(:focus-visible) {
    outline: none;
    box-shadow: none;
  }
`;

const ContactForm = () => {
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .matches(/^[a-zA-Z\s]+$/, 'Name should only contain letters and spaces')
      .required('Name is required'),
    email: Yup.string()
      .email('Invalid email format')
      .required('Email is required'),
    phone: Yup.string()
      .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits')
      .required('Phone number is required'),
    message: Yup.string()
      .required('Message is required'),
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      phone: '',
      message: '',
    },
    validationSchema,
    onSubmit: (values, { resetForm }) => {
      // Handle form submission here
      console.log(values);

      // Clear the form fields
      resetForm();
    },
  });

  return (
    <StyledContainer className='my-md-5'>
      <Row>
        <Col md={6} className="m-auto">
          <Subtitle>Get Started</Subtitle>
          {/* <h3 className='fs-2 fw-bold w-auto'>Secure Your Coverage Today with <span style={{ color: '#EF5226' }}>Legacy Insurance Brokers</span></h3> */}
          <Title>Secure Your Coverage <br/>Today with  <span style={{ color: '#EF5226' }}>Legacy Insurance Brokers</span></Title>
          <ul className='list-unstyled'>
            <p className='fs-5 fw-bold mt-4 mb-1'>Contact Info</p>
            <IconContainer>
              <div className="icon d-flex justify-content-center align-items-center">
                <FaPhoneAlt className='fs-3'/>
              </div>
              <div className="text-container">
                <span className="label">Phone Number</span>
                <span className="details">+91 8919439603</span>
              </div>
            </IconContainer>
            <IconContainer>
              <div className="icon d-flex justify-content-center align-items-center">
                <FaMapMarkerAlt className='fs-3'/>
              </div>
              <div className="text-container">
                <span className="label">Address</span>
                <span className="details">Office Unit No. 705, Jain Sadguru Capital Park Madhapur, <br/>Hyderabad-500081</span>
              </div>
            </IconContainer>
            <IconContainer>
              <div className="icon d-flex justify-content-center align-items-center">
                <FaEnvelope className='fs-3'/>
              </div>
              <div className="text-container">
                <span className="label">Email</span>
                <span className="details">info@lib.com</span>
              </div>
            </IconContainer>
          </ul>
        </Col>
        <Col md={6}>
          <StyledForm onSubmit={formik.handleSubmit}>
            <StyledFormGroup controlId="formName">
              <Form.Label>Your Name</Form.Label>
              <Form.Control
                type="text"
                name="name"
                placeholder="Enter your name"
                {...formik.getFieldProps('name')}
              />
              {formik.touched.name && formik.errors.name ? (
                <div className="text-danger">{formik.errors.name}</div>
              ) : null}
            </StyledFormGroup>

            <StyledFormGroup controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                name="email"
                placeholder="Enter your email"
                {...formik.getFieldProps('email')}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="text-danger">{formik.errors.email}</div>
              ) : null}
            </StyledFormGroup>

            <StyledFormGroup controlId="formPhone">
              <Form.Label>Phone Number</Form.Label>
              <Form.Control
                type="text"
                name="phone"
                placeholder="Enter your phone number"
                {...formik.getFieldProps('phone')}
              />
              {formik.touched.phone && formik.errors.phone ? (
                <div className="text-danger">{formik.errors.phone}</div>
              ) : null}
            </StyledFormGroup>

            <StyledFormGroup controlId="formMessage">
              <Form.Label>Your Message</Form.Label>
              <Form.Control
                as="textarea"
                name="message"
                rows={4}
                placeholder="Enter your message"
                {...formik.getFieldProps('message')}
              />
              {formik.touched.message && formik.errors.message ? (
                <div className="text-danger">{formik.errors.message}</div>
              ) : null}
            </StyledFormGroup>

            <StyledButton variant="primary" type="submit">
              Send Message
            </StyledButton>
          </StyledForm>
        </Col>
      </Row>
    </StyledContainer>
  );
};

export default ContactForm;