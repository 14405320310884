import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import styled from 'styled-components';
import { Subtitle } from './AboutUsSection';
import { BlueButton } from './AboutUsSection';
import { useNavigate } from 'react-router-dom';

const Section = styled.section`
  padding: 60px 0;
  background-color: #f8f9fa;

  @media screen and (max-width: 992px) {
    padding: 40px 0;
  }
`;

export const Title = styled.h2`
  font-size: 40px;
  font-weight: 700;
  color: #004643;
  margin-bottom: 40px;

  @media screen and (max-width: 1024px) {
    font-size: 32px;
    margin-bottom: 30px;
  }

  @media screen and (max-width: 768px) {
    font-size: 28px;
    margin-bottom: 20px;
  }
`;

const ServiceCard = styled.div`
  background-color: #e0f7fa;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 30px;
  text-align: left;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.15);
  }

  @media screen and (max-width: 992px) {
    padding: 15px;
  }
`;

const ServiceIcon = styled.div`
  font-size: 40px;
  width: 80px;
  color: white;
  padding: 15px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
  background-color: #024656;

  @media screen and (max-width: 992px) {
    font-size: 30px;
    width: 45px;
    padding: 10px;
  }
`;

const ServiceTitle = styled.h4`
  font-size: 24px;
  color: #004643;
  font-weight: 700;
  margin-bottom: 10px;

  @media screen and (max-width: 992px) {
    font-size: 20px;
  }
`;

const ServiceDescription = styled.p`
  font-size: 16px;
  color: #6c757d;

  @media screen and (max-width: 992px) {
    font-size: 14px;
  }
`;

const Services= ({ Services }) => {
  const navigate = useNavigate();

  const handleSeeMore = (path) => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Scroll to top
    navigate(path); // Navigate to the new page
  };

  return (
    <Section>
      <Container className='mt-md-5'>
        <Subtitle>Our Services</Subtitle>
        <Row className="justify-content-between">
          <Col md={6} className="mb-3">
            <Title>Our Range of Services</Title>
          </Col>
          <Col md={6} className="text-end mb-3">
            <Button variant="link" className="text-danger">
              See All Services
            </Button>
          </Col>
        </Row>
        <Row>
          {Services.map((service, index) => (
            <Col md={6} key={index} className="mb-3">
              <ServiceCard>
                <Row>
                  <Col xs={3} sm={2} className="mb-2 mb-md-0">
                    <ServiceIcon>{service.icon}</ServiceIcon>
                  </Col>
                  <Col xs={9} sm={10}>
                    <ServiceTitle>{service.title}</ServiceTitle>
                    <ServiceDescription>{service.description}</ServiceDescription>
                    <BlueButton onClick={() => handleSeeMore(service.path)}>See more</BlueButton>
                  </Col>
                </Row>
              </ServiceCard>
            </Col>
          ))}
        </Row>
      </Container>
    </Section>
  );
};

export default Services;
