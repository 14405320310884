import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';

export const StyledTitle = styled.h1`
  text-align: center;
  @media (min-width: 768px) {
    text-align: start;
  }
  font-weight: bold;
  span {
    color: #EF5226;
  }
`;

const StyledContainer = styled(Container)`
  background-color: #fff;
  padding: 2rem 1rem;

  @media (max-width: 768px) {
    padding: 1.5rem 0.5rem;
  }
`;

export const StyledImage = styled.img`
  width: 100%;
  max-width: 532px;
  height: auto;
  margin-top: 1rem;
  border-radius: 15px;

  @media (max-width: 768px) {
    margin-top: 2rem;
  }
`;

const BikeInsuranceSection = ({InsuranceTopIntroSection}) => {
  return (
    <StyledContainer>
      <Row className="align-items-center">
        <Col lg={6} md={12} className='text-md-start'>
        <StyledTitle>
          {InsuranceTopIntroSection.title.part1} <span>{InsuranceTopIntroSection.title.part2}</span>
        </StyledTitle>
          {InsuranceTopIntroSection.description.map((text, index) => (
            <p key={index}>{text}</p>
          ))}
        </Col>
        <Col lg={6} md={12} className='text-center text-md-end'>
          <StyledImage
            src={InsuranceTopIntroSection.image}
            alt={InsuranceTopIntroSection.altText}
          />
        </Col>
      </Row>
    </StyledContainer>
  );
};

export default BikeInsuranceSection;
