import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import styled from 'styled-components';
import { Formik } from 'formik';
import * as Yup from 'yup';
import emailjs from '@emailjs/browser';  // Import EmailJS

import { constants } from '../utils/constants';

const PopupModal = styled(Modal)`
  .modal-content {
    border-radius: 10px;
    padding: 0;
    position: relative;
  }
  @media screen and (min-width: 992px) {
    .modal-dialog {
      max-width: 50vw;
    }
  }
`;

const PopupBody = styled(Modal.Body)`
  display: flex;
  flex-direction: row;
  padding: 0;
  position: relative;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  position: relative; /* For positioning close button on mobile */
  background: url(${constants.popup_img}) no-repeat center center;
  background-size: cover;
  min-height: 300px;
  @media (max-width: 768px) {
    border-radius: 10px 10px 0 0;
    min-height: 200px;
  }
`;

const FormContainer = styled.div`
  flex: 1;
  padding: 20px;
  border-radius: 0 10px 10px 0;
  position: relative; /* For positioning close button on large devices */
  @media (max-width: 768px) {
    border-radius: 0 0 10px 10px;
  }
`;

// const SuccessMessage = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100%;
//   color: #004643;
//   font-size: 20px;
//   text-align: center;
//   flex-direction: column;
//   padding: 20px;
// `;

const SuccessMessage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #004643;
  font-size: 20px;
  text-align: center;
  flex-direction: column;
  padding: 20px;
  width: 100%; /* Ensure it takes full width */
  min-height: 200px; /* Set a minimum height to handle smaller content */
`;

const BackgroundOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(8px);
  z-index: 1040;
`;

const CloseButton = styled(Button)`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 36px;
  height: 36px;
  font-size: 1.5rem; /* Increase font size for the close icon */
  background-color: #1C274C;
  border: none;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  border-radius: 50%; /* Make it circular */
  
  &:hover {
    background-color: #1C274C;
  }

  /* For large screens, position the close button at the top-right of the form container */
  @media (min-width: 769px) {
    z-index: 1050;
  }

  /* For small screens, position the close button at the top-right of the image container */
  @media (max-width: 768px) {
    z-index: 1050;
  }

  /* Additional styling for the icon itself */
  span {
  margin: 0px auto;
    font-weight: bold;
    font-size: 25px;
  }
`;
const validationSchema = Yup.object().shape({
  name: Yup.string()
    .matches(/^[a-zA-Z\s]+$/, 'Name should only contain letters and spaces')
    .min(2, 'Too Short!')
    .max(50, 'Too Long!')
    .required('Name is required'),
  mobile: Yup.string()
    .matches(/^[0-9]{10}$/, 'Mobile number is not valid')
    .required('Mobile number is required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Email is required'),
  message: Yup.string()
    .min(10, 'Message is too short!')
    .required('Message is required'),
});

const Popup = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => setShowPopup(false);

  const sendEmail = (values) => {
    const serviceID = 'service_m30a3s4';
    const templateID = 'template_nf0g9gx';
    const publicKey = 'X5ollWWLks-040DIT';

    const templateParams = {
      name: values.name,
      mobile: values.mobile,
      email: values.email,
      message: values.message,
    };

    emailjs.send(serviceID, templateID, templateParams, publicKey)
      .then((response) => {
        console.log('Email sent successfully!', response.status, response.text);
        setSubmitted(true);
      })
      .catch((err) => {
        console.error('Failed to send email:', err);
      });
  };

  return (
    <>
      {showPopup && <BackgroundOverlay />}
      <PopupModal show={showPopup} onHide={handleClose} centered>
        <PopupBody>
          {!submitted ? (
            <>
              <ImageContainer>
                <CloseButton onClick={handleClose} aria-label="Close" className='d-md-none d-block'>
                  <span aria-hidden="true">&times;</span>
                </CloseButton>
              </ImageContainer>
              <FormContainer>
                <CloseButton onClick={handleClose} aria-label="Close" className="d-none d-md-block">
                  <span aria-hidden="true">&times;</span>
                </CloseButton>
                <h2 className='fs-1 fw-bolder' style={{color: '#EF5226'}}>Reach out for coverage</h2>
                <h2 className='fs-6 '>Stay informed with the latest industry insights and updates essential for your business.</h2>
                <Formik
                  initialValues={{ name: '', mobile: '', email: '', message: '' }}
                  validationSchema={validationSchema}
                  onSubmit={(values, { resetForm }) => {
                    sendEmail(values);  // Send email when form is submitted
                    resetForm();
                  }}
                >
                  {({ values, errors, touched, handleChange, handleSubmit }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      {/* Form groups for name, mobile, email, and message */}
                      <Form.Group controlId="formName">
                        <Form.Label>Name</Form.Label>
                        <Form.Control
                          type="text"
                          name="name"
                          placeholder="Enter your name"
                          value={values.name}
                          onChange={handleChange}
                          isInvalid={!!errors.name && touched.name}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.name}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group controlId="formMobile" className="mt-3">
                        <Form.Label>Mobile Number</Form.Label>
                        <Form.Control
                          type="tel"
                          name="mobile"
                          placeholder="Enter your mobile number"
                          value={values.mobile}
                          onChange={handleChange}
                          isInvalid={!!errors.mobile && touched.mobile}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.mobile}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group controlId="formEmail" className="mt-3">
                        <Form.Label>Email</Form.Label>
                        <Form.Control
                          type="email"
                          name="email"
                          placeholder="Enter your email"
                          value={values.email}
                          onChange={handleChange}
                          isInvalid={!!errors.email && touched.email}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </Form.Group>

                      <Form.Group controlId="formMessage" className="mt-3">
                        <Form.Label>Message</Form.Label>
                        <Form.Control
                          as="textarea"
                          name="message"
                          placeholder="Enter your message"
                          rows={3}
                          value={values.message}
                          onChange={handleChange}
                          isInvalid={!!errors.message && touched.message}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.message}
                        </Form.Control.Feedback>
                      </Form.Group>
                      <Button style={{ backgroundColor: '#024656' }} type="submit" className="mt-4 w-100">
                        Submit
                      </Button>
                    </Form>
                  )}
                </Formik>
              </FormContainer>
            </>
          ) : (
            <SuccessMessage>
              <CloseButton onClick={handleClose} aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </CloseButton>
              <h4>Thank You!</h4>
              <p>Relax, our team will get back to you shortly!</p>
            </SuccessMessage>
          )}
        </PopupBody>
      </PopupModal>
    </>
  );
};

export default Popup;