import React from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import styled from 'styled-components';
import { WorkProcessContent } from '../utils/constants';
import { FaChevronRight } from 'react-icons/fa';
import {Subtitle} from './AboutUsSection';
import { Title } from './ServicesSection';
import { StyledButton } from './GetStartedForm';

// Styled Components
const Section = styled.section`
  padding: 30px 0;
  background-color: #fff;
`;

const Header = styled.div`
  text-align: center;
  margin-bottom: 50px;
`;

const StepTitle = styled.h3`
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
  color: #024656;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  gap: 15px;
`;

const StepNumber = styled.span`
  font-size: 24px;
  font-weight: 700;
  line-height: 28.8px;
  letter-spacing: -0.02em;
  text-align: left;
  color: #B2E4EF;
`;

const StepDescription = styled.div`
  font-size: 16px;
  color: #6c757d;
  margin-bottom: 20px;
`;

const IconWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  width: 6px;
  height: 9px;
  gap: 0px;
  margin-right: 8px;
  color: #004643;
`;

const StepText = styled.span`
  font-size: 18px;
  line-height: 28.8px;
  letter-spacing: -0.03em;
  text-align: left;
  font-weight: 600;
  color: #004643;
`;
const TitleSpan = styled.span`
  color: #ef5226;
  font-size: 40px;
  font-weight: 700;

  @media screen and (max-width: 992px){
    font-size: 24px;
  }
`;

const StyledImage = styled.img`
  width: 520px;
  // height: 296px;
  border-radius: 10px;
`;

const WorkProcessSection = () => {
  const { work_process_steps } = WorkProcessContent;

  return (
    <Section>
      <Container className='mt-md-5'>
        {/* Header */}
        <Header>
          <Subtitle className='text-center'>How It Works</Subtitle>
          <Title>
            How Our Insurance <TitleSpan>Process Works</TitleSpan>
          </Title>
        </Header>

        {/* Step 1 */}
        <Row className="align-items-start mb-5">
          <Col md={6}>
            <StyledImage src={WorkProcessContent.home_work_process_1} alt="Consultation and Assessment" className="img-fluid" />
          </Col>
          <Col md={6}>
            <StepTitle>
              <StepNumber>01</StepNumber>
              {work_process_steps.step1.title}
            </StepTitle>
            {work_process_steps.step1.description.map((desc, index) => (
              <StepDescription className='ps-3' key={index}>
                <IconWrapper>
                  <FaChevronRight />
                </IconWrapper>
                <StepText>{desc.text}</StepText>
                <br />
                {desc.detail}
              </StepDescription>
            ))}
          </Col>
        </Row>

        {/* Step 2 */}
        <Row className="align-items-start mb-5">
          <Col md={6} className="order-md-2">
            <StyledImage src={WorkProcessContent.home_work_process_2} alt="Customized Plan Selection" className="img-fluid" />
          </Col>
          <Col md={6} className="order-md-1">
            <StepTitle>
              <StepNumber>02</StepNumber>
              {work_process_steps.step2.title}
            </StepTitle>
            {work_process_steps.step2.description.map((desc, index) => (
              <StepDescription className='ps-3' key={index}>
                <IconWrapper>
                  <FaChevronRight />
                </IconWrapper>
                <StepText>{desc.text}</StepText>
                <br />
                {desc.detail}
              </StepDescription>
            ))}
          </Col>
        </Row>

        {/* Step 3 */}
        <Row className="align-items-start">
          <Col md={6}>
            <StyledImage src={WorkProcessContent.home_work_process_3} alt="Enrollment and Coverage Activation" className="img-fluid" />
          </Col>
          <Col md={6}>
            <StepTitle>
              <StepNumber>03</StepNumber>
              {work_process_steps.step3.title}
            </StepTitle>
            {work_process_steps.step3.description.map((desc, index) => (
              <StepDescription className='ps-3' key={index}>
                <IconWrapper>
                  <FaChevronRight />
                </IconWrapper>
                <StepText>{desc.text}</StepText>
                <br />
                {desc.detail}
              </StepDescription>
            ))}
          </Col>
        </Row>

        {/* Call to Action */}
        <div className="text-center my-5">
          <StyledButton>Get Started</StyledButton>
        </div>
      </Container>
    </Section>
  );
};

export default WorkProcessSection;