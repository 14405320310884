import React from 'react';
import styled from 'styled-components';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { missionContent } from '../utils/constants'; 

// Styled Components for Mission Section
const StyledContainer = styled(Container)`
  padding: 80px 0;
`;

const SectionTitle = styled.h6`
  color: #f76c6c;
  font-weight: bold;
  margin-bottom: 20px;
  font-size: 18px;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

const Heading = styled.h2`
  color: #003c5f;
  font-size: 48px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 40px;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 30px;
    text-align: center;
  }
`;

const MissionItem = styled.div`
 display: flex;
    // width: 430px;
    height: 117px;
    gap: 40px;
    align-items: center;
    margin-bottom: 40px;
}

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
`;

const Number = styled.div`
  font-size: 36px;
  font-weight: bold;
  color: #003c5f;
  margin-right: 20px;
  display: flex;   /* Ensure the number stays centered vertically */
  align-items: center;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

const MissionText = styled.div`
  flex-grow: 1;  /* Ensures the text takes available space */
  text-align: left;

  @media (max-width: 768px) {
    text-align: left;
  }
`;


const MissionHeading = styled.h5`
  color: #003c5f;
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
`;

const MissionDescription = styled.p`
  color: #6c757d;
  font-weight: 500;
  line-height: 1.5;
  font-size: 18px;
  margin: 0;
`;

const ImageContainer = styled.div`
  position: relative;
  max-width: 520px;
  margin: 0 auto;
`;

const MainImage = styled(Image)`
  width: 100%;
  height: auto;
  border-radius: 8px;
`;

const OverlayImage = styled(Image)`
  position: absolute;
  // bottom: -50px;
  top: 257px;
  left: -30px;
  width: 210px;
  border-radius: 8px;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.2);

  @media (max-width: 768px) {
    left: 50%;
    display:none;
    transform: translateX(-50%);
    bottom: -30px;
  }
`;

// MissionSection Component
const MissionSection = () => {
  const { sectionTitle, heading, items, mainImage, overlayImage } = missionContent;

  return (
    <StyledContainer>
      <Row>
        <Col md={6}>
          <SectionTitle>{sectionTitle}</SectionTitle>
          <Heading>{heading}</Heading>
          {items.map((item, index) => (
            <MissionItem key={index}>
              <Number>{item.number}</Number>
              <MissionText>
                <MissionHeading>{item.title}</MissionHeading>
                <MissionDescription>{item.description}</MissionDescription>
              </MissionText>
            </MissionItem>
          ))}
        </Col>
        <Col md={6}>
          <ImageContainer>
            <MainImage src={mainImage} alt="Main Mission Image" />
            <OverlayImage src={overlayImage} alt="Overlay Family Image" />
          </ImageContainer>
        </Col>
      </Row>
    </StyledContainer>
  );
};

export default MissionSection;
