import React from 'react';
import styled from 'styled-components';
import { Col, Container, Row } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';  // Import useLocation hook
import { StyledButton } from './GetStartedForm';
// Create a styled component for the background container
const BgContainer = styled.div`
    background-image: url(${props => props.bgImageLandscape});
    background-size: cover;
    background-position: center;
    object-fit: contain;
    width: 100%;
    height: 75vh;  /* Adjust height as needed */
    display: flex;
    justify-content: center;
    align-items: center;

    /* Media query for tablet devices */
    @media (max-width: 991px) {
        background-image: url(${props => props.bgImageLandscape});
    }

    /* Media query for mobile devices */
    @media (max-width: 767px) {
        background-image: url(${props => props.bgImagePortrait});
    }
`;

// Styled input field
const InputField = styled.input`
    background-color: white;
    border: none;
    color: black;
    padding: 12px;
    text-align: left;
    border-radius: 6px;
    font-weight: 500;
    width: 15vw;
    height: 50px;
    margin-right: 10px;

    /* Media query for mobile devices */
    @media (max-width: 767px) {
        width: auto;
    }
`;
// Dynamic LandingSection component
const LandingSection = ({ LandingData }) => {
  const { title, description, inputPlaceholder, buttonText, listItem, bg_landscape, bg_portrait } = LandingData;

  const location = useLocation();  // Get the current location

  // Check if the current path is `/car` or `/bike`
  const shouldShowInput = ['/car', '/bike'].includes(location.pathname);

  return (
    <BgContainer bgImageLandscape={bg_landscape} bgImagePortrait={bg_portrait}>
      <Container>
        <Row>
          <Col md={6} lg={4} xs={12} className='w-auto d-flex flex-column align-items-center align-items-md-start'>       
            <div className='text-white text-center text-md-start'>
              <span style={{ display: 'inline-block', color: '#EF5226', fontSize: '24px', marginRight: '10px' }}>
                &#x25A0; {/* Unicode for square bullet */}
              </span>
              <span className='text-white fs-5 m-0'>{listItem}</span>
            </div>
            <h1 className='text-white text-center text-md-start' style={{ width: window.innerWidth >= 992 ? "75%" : "100%" }}>
              {title}
            </h1>
            <p className='text-white text-center text-md-start' style={{ width: window.innerWidth >= 992 ? "50%" : "100%" }}>
              {description}
            </p>
            <div className='d-flex'>
                {shouldShowInput && <InputField placeholder={inputPlaceholder} />}
                <StyledButton>{buttonText}</StyledButton>
            </div>
          </Col>
        </Row>
      </Container>
    </BgContainer>
  );
}

export default LandingSection;