import React from 'react';
import LandingSection from '../../components/TopBannerSection';
import IntroSection from '../../components/IntroSection';
import ExploreSection from '../../components/ExploreSection';
import CarInsurancePlans from '../../components/InsurancePlansSection';
import Testimonials from '../../components/TestimonialsSection';
import OurPartners from '../../components/OurPartnersSection';
import { insurance_plans, InsuranceTopIntroSection, testimonialsData, ExploreSectionData, constants, serviceList } from '../../utils/constants';
import ServicesSection from '../../components/ServicesSection';
import FaqSection from '../../components/FaqSection';

const Car = () => {
    return ( 
    <>
    <LandingSection LandingData={constants.Car_landing}/>
    <IntroSection InsuranceTopIntroSection={InsuranceTopIntroSection.carIntroSection}/>
    <ExploreSection ExploreSectionData={ExploreSectionData.car}/>
    <CarInsurancePlans insurance_plans={insurance_plans}/>
    <ServicesSection Services={serviceList.Car} />
    <Testimonials testimonialsData={testimonialsData.car_testimonials}/>
    <OurPartners/>
    <FaqSection/>
    </>
     );
}

export default Car;