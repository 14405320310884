// Services.js
import React from "react";
import LandingSection from "../../components/TopBannerSection";
import {  constants } from "../../utils/constants";
import AboutUsSection from "../../components/AboutUsSection";
import OurPartners from "../../components/OurPartnersSection";
import  GetStartedForm  from "../../components/GetStartedForm";
import CallToAction from "../../components/Footer/CallToAction";
import ValueSection from "../../components/ValueSection";
import MissionSection from "../../components/MissionSection";

const AboutUs = () => {
  return (
    <>
      <LandingSection LandingData={constants.AboutUs_landing} />
     <AboutUsSection/>
     <OurPartners  />
     <MissionSection />
     <GetStartedForm  />
     <ValueSection />
     <CallToAction />
    </>
  );
};

export default AboutUs;
