import React from 'react';
import styled, { keyframes } from 'styled-components';
import {Title} from './ServicesSection';

// Keyframe animations for scrolling effects
const scrollingLeftToRight = keyframes`
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
`;

const scrollingRightToLeft = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
`;

// Styled component for slider container
const SliderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  overflow: hidden;
  background: rgb(255, 255, 255);
  border-radius: 16px;
  backdrop-filter: blur(7.4px);
  -webkit-backdrop-filter: blur(7.4px);
  border: 1px solid rgba(255, 255, 255, 0.4);
  margin: 20px 0;
`;

const SliderImgContainer = styled.div`
  border: 1px solid #05A7CC;
  border-radius: 12px;
  box-shadow: 0px 2px 4px 2px #e6f6fa;
`;

// Styled component for slider items
const SliderItems = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

// Styled component for images
const SliderImage = styled.img`
  height: 75px;
  width: 200px;
  object-fit: contain;
`;

// Styled component for animation container
const AnimationWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  padding: 1vh;
`;

const LeftToRight = styled(SliderItems)`
  animation: ${scrollingLeftToRight} 80s linear infinite;
`;

const RightToLeft = styled(SliderItems)`
  animation: ${scrollingRightToLeft} 80s linear infinite;
`;

const OurPartners = () => {
  const first_row_images = [
    require('../assets/insurance_company_logos/Aditya-Birla-Insurance.webp'),
    require('../assets/insurance_company_logos/Future-Generali-Insurance.webp'),
    require('../assets/insurance_company_logos/Kotak-General-Insurance.webp'),
    require('../assets/insurance_company_logos/National-Insurance.webp'),
    require('../assets/insurance_company_logos/New-India-Assurance-Insurance.webp'),
    require('../assets/insurance_company_logos/TATA-AIG-Insurance.webp'),
    require('../assets/insurance_company_logos/United-India-Insurance.webp'),
    require('../assets/insurance_company_logos/Cholamandalam-Insurance.webp'),
    require('../assets/insurance_company_logos/Universal_Sompo-1.webp'),
    require('../assets/insurance_company_logos/Reliance-Insurance.webp'),
    require('../assets/insurance_company_logos/Oriental-Insurance.webp')
  ];

  const second_row_images = [
    require('../assets/insurance_company_logos/SBI-Insurance.webp'),
    require('../assets/insurance_company_logos/Star-Insurance.webp'),
    require('../assets/insurance_company_logos/Niva-Bupa-Insurance.webp'),
    require('../assets/insurance_company_logos/Manipal-Cigna-Insurance.webp'),
    require('../assets/insurance_company_logos/Bajaj-Allianz-Insurance.webp'),
    require('../assets/insurance_company_logos/Birla_Sunlife_Insurance.webp'),
    require('../assets/insurance_company_logos/Canara_HSBC_Insurance.webp'),
    require('../assets/insurance_company_logos/Edelweis_Tokio_Insurance.webp'),
    require('../assets/insurance_company_logos/Exide-insurance.webp'),
    require('../assets/insurance_company_logos/HDFC_Life_Insurance.webp'),
    require('../assets/insurance_company_logos/HDFC-ERGO-Insurance.webp')
  ];

  const third_row_images = [
    require('../assets/insurance_company_logos/Reliance-Insurance.webp'),
    require('../assets/insurance_company_logos/Oriental-Insurance.webp'),
    require('../assets/insurance_company_logos/Canara_HSBC_Insurance.webp'),
    require('../assets/insurance_company_logos/ICICI-Lombard-Insurance.webp'),
    require('../assets/insurance_company_logos/ICICI_Pru_Insurance.webp'),
    require('../assets/insurance_company_logos/IDBI_Fed_Insurance.webp'),
    require('../assets/insurance_company_logos/IFFCO-Tokio-Insurance.webp'),
    require('../assets/insurance_company_logos/Care-Insurance.webp'),
    require('../assets/insurance_company_logos/United-India-Insurance.webp'),
    require('../assets/insurance_company_logos/Cholamandalam-Insurance.webp'),
    require('../assets/insurance_company_logos/Universal_Sompo-1.webp'),
  ]

  return (
    <>
      <div className='my-md-5'>
      <Title className='text-center pt-4 pt-md-0'>Our Partners</Title>
      <SliderContainer>
        <AnimationWrapper>
          <RightToLeft>
            {first_row_images.concat(first_row_images).map((src, index) => (
              <SliderImgContainer key={index}>
                <SliderImage src={src} alt={`Partner ${index}`} />
              </SliderImgContainer>
            ))}
          </RightToLeft>
        </AnimationWrapper>
      </SliderContainer>
      <SliderContainer>
        <AnimationWrapper>
          <LeftToRight>
            {second_row_images.concat(second_row_images).map((src, index) => (
              <SliderImgContainer key={index}>
                <SliderImage src={src} alt={`Partner ${index}`} />
              </SliderImgContainer>
            ))}
          </LeftToRight>
        </AnimationWrapper>
      </SliderContainer>
      <SliderContainer>
        <AnimationWrapper>
          <RightToLeft>
            {third_row_images.concat(third_row_images).map((src, index) => (
              <SliderImgContainer key={index}>
                <SliderImage src={src} alt={`Partner ${index}`} />
              </SliderImgContainer>
            ))}
          </RightToLeft>
        </AnimationWrapper>
      </SliderContainer>
      </div>
    </>
  );
};

export default OurPartners;
