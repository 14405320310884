import React from 'react';
import LandingSection from '../../components/TopBannerSection';
import { constants, ExploreSectionData, insurance_plans, InsuranceTopIntroSection, serviceList, testimonialsData } from '../../utils/constants';
import IntroSection from '../../components/IntroSection';
import ExploreSection from '../../components/ExploreSection';
import CarInsurancePlans from '../../components/InsurancePlansSection';
import Testimonials from '../../components/TestimonialsSection';
import OurPartners from '../../components/OurPartnersSection';
import FaqSection from '../../components/FaqSection';
import ServicesSection from '../../components/ServicesSection';
const Health = () => {
    return ( 
        <>
        <LandingSection LandingData={constants.Health_landing}/>
        <IntroSection InsuranceTopIntroSection={InsuranceTopIntroSection.healthIntroSection}/>
        <ExploreSection ExploreSectionData={ExploreSectionData.health}/>
        <CarInsurancePlans insurance_plans={insurance_plans}/>
        <ServicesSection Services={serviceList.Health} />
        <Testimonials testimonialsData={testimonialsData.car_testimonials}/>
        <OurPartners/>
        <FaqSection/>
        </>
     );
}

export default Health;


