import React from 'react'
import LandingSection from '../../components/TopBannerSection'
import { constants, ExploreSectionData, insurance_plans, InsuranceTopIntroSection, serviceList, testimonialsData } from '../../utils/constants'
import IntroSection from '../../components/IntroSection'
import ExploreSection from '../../components/ExploreSection'
import CarInsurancePlans from '../../components/InsurancePlansSection'
import Testimonials from '../../components/TestimonialsSection'
import OurPartners from '../../components/OurPartnersSection'
import ServicesSection from '../../components/ServicesSection'
import FaqSection from '../../components/FaqSection'

const Life = () => {
  return (
    <>
    <LandingSection LandingData={constants.Life_landing}/>
    <IntroSection InsuranceTopIntroSection={InsuranceTopIntroSection.lifeIntroSection}/>
    <ExploreSection ExploreSectionData={ExploreSectionData.life}/>
    <CarInsurancePlans insurance_plans={insurance_plans}/>
    <ServicesSection Services={serviceList.Life} />
    <Testimonials testimonialsData={testimonialsData.car_testimonials}/>
    <OurPartners/>
    <FaqSection />
    </>
  )
}

export default Life
