import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import styled, { keyframes } from 'styled-components';
import { heroBannerContent } from '../utils/constants';

// Keyframes for fade-in animations
const fadeInLeft = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

const fadeInRight = keyframes`
  0% {
    opacity: 0;
    transform: translateX(50px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

// Styled Components
const HeroSection = styled.section`
  background-image: url(${heroBannerContent.backgroundImage});
  background-size: cover;
  background-position: 100% 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  text-align: left;

  @media (max-width: 1024px) {
    background-position: center;
    padding: 20px;
  }

  @media (max-width: 768px) {
    height: 75vh;
    padding: 15px;
  }

  @media (max-width: 576px) {
    height: 70vh;
    padding: 10px;
  }
`;

const Heading = styled.h1`
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #fff;
  animation: ${fadeInLeft} 1s ease-in-out;

  span {
    color: #ff5722;
  }

  @media (max-width: 1024px) {
    font-size: 1.4rem;
  }

  @media (max-width: 768px) {
    font-size: 2rem;
  }

  @media (max-width: 576px) {
    font-size: 1.1rem;
  }
`;

const Subheading = styled.p`
  font-size: 1.1rem;
  line-height: 1.5;
  margin-bottom: 20px;
  color: #ddd;
  animation: ${fadeInLeft} 1.2s ease-in-out;

  @media (max-width: 1024px) {
    font-size: 1.1rem;
  }

  @media (max-width: 768px) {
    font-size: 1.2rem;
  }

  @media (max-width: 576px) {
    font-size: 14px;
  }
`;

const QuoteButton = styled(Button)`
  background-color: #ff5722;
  border: none;
  font-size: 1rem;
  padding: 10px 20px;
  animation: ${fadeInLeft} 1.4s ease-in-out;

  &:hover {
    background-color: #e64a19;
  }

  @media (max-width: 1024px) {
    font-size: 0.9rem;
    padding: 8px 18px;
  }

  @media (max-width: 768px) {
    font-size: 0.8rem;
    padding: 7px 16px;
  }

  @media (max-width: 576px) {
    font-size: 0.75rem;
    padding: 6px 14px;
  }
`;

const ImageContainer = styled.div`
  text-align: center;
  margin-top: 20px;
  animation: ${fadeInRight} 1.6s ease-in-out;

  img {
    max-width: 100%;
    height: auto;

    @media (max-width: 1024px) {
      max-width: 90%;
    }

    @media (max-width: 768px) {
      max-width: 80%;
    }

    @media (max-width: 576px) {
      max-width: 100%;
    }
  }
`;

const HomeBannerSection = () => {
  return (
    <HeroSection>
      <Container>
        <Row className="align-items-center">
          <Col xs={12} md={6}>
            <Heading>
              {heroBannerContent.heading}
              <br />
              Insurance <span>Solutions</span> by
              <br />
              Legacy Insurance Brokers
            </Heading>
            <Subheading>{heroBannerContent.subHeading}</Subheading>
            <QuoteButton>{heroBannerContent.buttonText}</QuoteButton>
          </Col>
          <Col xs={12} md={6}>
            <ImageContainer>
              <img src={heroBannerContent.mainImage} alt="Family" />
            </ImageContainer>
          </Col>
        </Row>
      </Container>
    </HeroSection>
  );
};

export default HomeBannerSection;