

import React from 'react';
import { Container, Row, Col} from 'react-bootstrap';
import styled from 'styled-components';
import { StyledTitle } from './IntroSection';
import { StyledImage } from './IntroSection';

const SubHeading = styled.h2`
  font-size: 24px;
  font-weight: 700;
  line-height: 31.25px;
  text-align: left;
  color: #000;
`;

const FeaturesHeading = styled.h5`
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  margin-top: 20px;
`;

const DescriptionText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 19.6px;
  text-align: center;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    font-size: 14px;
    line-height: 19.6px;
  }
`;

const FlexRow = styled(Row)`
  @media (max-width: 768px) {
    .image-col {
      order: 2;
    }
    .text-col {
      order: 1;
    }
  }
`;

const ParagraphText = styled.p`
  font-size: 1rem;
  font-weight: 400;
  line-height: 19.6px;
  text-align: left;
`;

// Main component
const ExploreSection = ({ ExploreSectionData }) => {
  return (
    <Container className="text-center py-5">
        <StyledTitle className='text-center w-auto'>
          {ExploreSectionData.exploreIntro.title.part1} <span>{ExploreSectionData.exploreIntro.title.part2}</span> {ExploreSectionData.exploreIntro.title.part3}
          </StyledTitle>
      <DescriptionText>
        {ExploreSectionData.exploreIntro.description}
      </DescriptionText>

        {ExploreSectionData.sections.map((section, index) => (
        <FlexRow className="mb-5 d-flex justify-content-center" key={index}>
          {index % 2 === 0 ? (
            <>
              {/* Image on the left for even index */}
              <Col md={5} className="d-flex align-items-center justify-content-between image-col">
                <StyledImage src={section.image} alt={section.altText} fluid />
              </Col>
              <Col md={5} className="text-start d-flex align-items-center text-col justify-content-between">
                <div>
                  <SubHeading>{section.title}</SubHeading>
                  <ParagraphText style={{ width: window.innerWidth >= 992 ? "95%" : "100%" }}>{section.description}</ParagraphText>
                  <FeaturesHeading>Features:</FeaturesHeading>
                  <ul style={{ width: window.innerWidth >= 992 ? "95%" : "100%" }}>
                    {section.features.map((feature, i) => (
                      <li key={i}>{feature}</li>
                    ))}
                  </ul>
                </div>
              </Col>
            </>
          ) : (
            <>
              {/* Image on the right for odd index */}
              <Col md={5} className="text-start d-flex align-items-center text-col justify-content-between">
                <div>
                  <SubHeading>{section.title}</SubHeading>
                  <ParagraphText style={{ width: window.innerWidth >= 992 ? "95%" : "100%" }}>{section.description}</ParagraphText>
                  <FeaturesHeading>Features:</FeaturesHeading>
                  <ul style={{ width: window.innerWidth >= 992 ? "95%" : "100%" }}>
                    {section.features.map((feature, i) => (
                      <li key={i}>{feature}</li>
                    ))}
                  </ul>
                </div>
              </Col>
              <Col md={5} className="d-flex align-items-center justify-content-between image-col">
                <StyledImage src={section.image} alt={section.altText} fluid />
              </Col>
            </>
          )}
        </FlexRow>
      ))}
    </Container>
  );
};

export default ExploreSection;