import React from 'react';
import styled from 'styled-components';
import { constants } from '../../utils/constants';
import { Container, Row, Col } from 'react-bootstrap';

const BgContainer = styled.div`
  background-image: url(${constants.cta_bg_img});
  background-size: 100% 100%;
  background-position: center;
  width: 100%;
  height: 400px;  /* Adjust height as needed */
  display: flex;
  justify-content: center;
  align-items: center;
  /* Media query for tablet devices */
  @media (max-width: 991px) {
    background-image: url(${constants.cta_bg_img});
  }

  /* Media query for mobile devices */
  @media (max-width: 767px) {
    background-image: url(${constants.cta_bg_img_mobile});
  }
`;

const Button = styled.button`
  background-color: #EF5226;
  border: none;
  color: white;
  padding: 10px 15px;
  text-align: center;
  border-radius: 12px;
  font-weight: 500;
  `;

const CallToAction = () => {
  return (
    <BgContainer>
            <Container>
                <Row>
                    <Col md={6} lg={4} xs={12} className='m-auto w-auto'>
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <h3 className='text-white fs-2 text-center w-75 fw-bold'>Ready to <span style={{ color: '#EF5226' }}>Safeguard</span> Your Lifelong Assets?</h3>
                            <p className='text-white fs-5 text-center w-100'>Secure your lifelong assets with comprehensive and reliable car insurance.</p>
                            <Button>Get Quotes</Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </BgContainer>
  );
};

export default CallToAction;
