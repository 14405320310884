import { AiOutlineSolution } from "react-icons/ai";
import { BiSolidCarGarage } from "react-icons/bi";
import { FaHeartPulse  } from "react-icons/fa6";
import { FaMotorcycle, FaHeartbeat } from "react-icons/fa";
import { BsUmbrella } from "react-icons/bs";
import { TfiCar } from "react-icons/tfi";
export const constants = {
  logo: require("../assets/logo.png"),
  popup_img: require("../assets/popupImg.png"),
  cta_bg_img: require("../assets/CTA.png"),
  cta_bg_img_mobile: require("../assets/cta_bg_img_mobile.png"),
  testimony_bg_img: require("../assets/Car/testimony_bg.png"),
  home_img_1:require("../assets/Home/about_img_1.webp"),
  home_img_2:require("../assets/Home/about-img_2.webp"),
  home_img_3:require("../assets/Home/about_img-3.webp"),
  testimonial_name_style: require("../assets/testimonial_name_design.png"),
  home_work_process_1:require("../assets/Home/work_process_img_1.webp"),
  home_work_process_3:require("../assets/Home/work_process_img_3.webp"),

  Car_landing: {
    title: "Protect Your Ride with Confidence",
    description: "Our expert team provides personalized service to find you the best rates and coverage. Enjoy significant savings and peace of mind with quick, hassle-free processes.",
    inputPlaceholder: 'Car Number (TS13NV1998)',
    buttonText: 'Get Quote',
    listItem: 'Car Insurance',
    bg_landscape: require("../assets/Car/car_bg_lanscape.webp"),
    bg_portrait: require("../assets/Car/car_bg_portrait.webp"),
  },
  bike_landing: {
    title: "Shield Your Ride with Confidence",
    description: "Our expert team offers personalized service to secure the best rates and coverage for your bike. Experience substantial savings and peace of mind with our quick, hassle-free process.",
    inputPlaceholder: 'Bike Number (TS24AG2001)',
    buttonText: 'Get Quote',
    listItem: 'Bike insurance',
    bg_landscape: require("../assets/Bike/bike_bg_lanscape.webp"),
    bg_portrait: require("../assets/Bike/bike_bg_portrait.webp"),
  },
  Health_landing: {
    title: "Safeguard Your Health with Confidence",
    description: "Our expert team provides personalized service to secure the best rates and coverage for your health. Enjoy significant savings and peace of mind with our quick, hassle-free process.",
    inputPlaceholder: 'Car Number (TS07FA5099)',
    buttonText: 'Get Quote',
    listItem: 'Health insurance',
    bg_landscape: require("../assets/Health/health_bg_landscape.webp"),
    bg_portrait: require("../assets/Health/health_bg_portrait.webp"),
  },
  Life_landing: {
    title: "Secure Your Life with Confidence",
    description: "Our expert team offers personalized service to secure the best rates and coverage for your life insurance. Experience substantial savings and peace of mind with our quick, hassle-free process.",
    inputPlaceholder: 'Car Number (TS07FA5099)',
    buttonText: 'Get Quote',
    listItem: 'Life insurance',
    bg_landscape: require("../assets/Life/life_bg_landscape.webp"),
    bg_portrait: require("../assets/Car/car_bg_portrait.webp"),
  },
  contact_landing: {
    title: "Your Trusted Partner in Protection Reach Out Today!",
    listItem: 'Contact us',
    buttonText: 'Get Quote',
    bg_landscape: require("../assets/Contact/contact_bg_landscape.webp"),
    bg_portrait: require("../assets/Contact/contact_bg_portrait.webp"),
  },
  services_landing: {
    title: "Personalized insurance services with dedicated attention",
    listItem: 'Introduce with our new insurances',
    buttonText: 'Contact us',
    bg_landscape: require("../assets/Services/services_bg_landscape.webp"),
    bg_portrait: require("../assets/Services/services_bg_portrait.webp"),
  },
  AboutUs_landing: {
    title: "Your Trusted Partner in Comprehensive Coverage",
    listItem: 'About us',
    buttonText: 'Contact us',
    bg_landscape: require("../assets/About/aboutus_bg_landscape.webp"),
    bg_portrait: require("../assets/About/aboutus_bg_portrait.webp"),
  },
}
export const insurance_plans = {
  "Cars": {
    "normal": [
      {
        "id": "car-1",
        "InsuranceCompany": "SBI Car Insurance",
        "ImageURL": require("../assets/insurance_company_logos/SBI-Insurance.webp"),
        "Cashless Garages": 6973,
        "Claims Settled": "98%",
        "Starting from": "3,067",
        "Check Premium": "Check Premium",
        "link": "/sbi-car-insurance",

      },
      {
        "id": "car-2",
        "InsuranceCompany": "Future Generali Car Insurance",
        "ImageURL": require("../assets/insurance_company_logos/Future-Generali-Insurance.webp"),
        "Cashless Garages": 4000,
        "Claims Settled": "93%",
        "Starting from": "3,225",
        "Check Premium": "Check Premium",
        link: "/future-car-insurance",

      },
      {
        "id": "car-3",
        "InsuranceCompany": "Kotak Car Insurance",
        "ImageURL": require("../assets/insurance_company_logos/Kotak-General-Insurance.webp"),
        "Cashless Garages": 4000,
        "Claims Settled": "99%",
        "Starting from": "3,374",
        "Check Premium": "Check Premium",
        link: "/kotak-car-insurance",

      },
      {
        "id": "car-4",
        "InsuranceCompany": "National Car Insurance",
        "ImageURL": require("../assets/insurance_company_logos/National-Insurance.webp"),
        "Cashless Garages": 3100,
        "Claims Settled": "86%",
        "Starting from": "2,757",
        "Check Premium": "Check Premium",
        link: "/national-car-insurance",
      }
    ],
    "see_more": [
      {
        "id": "car-5",
        "InsuranceCompany": "New India Assurance Car Insurance",
        "ImageURL": require("../assets/insurance_company_logos/New-India-Assurance-Insurance.webp"),
        "Cashless Garages": 3000,
        "Claims Settled": "93%",
        "Starting from": "2,494",
        "Check Premium": "Check Premium",
        link: "/new-india-car-insurance",

      },
      {
        "id": "car-6",
        "InsuranceCompany": "TATA AIG Car Insurance",
        "ImageURL": require("../assets/insurance_company_logos/TATA-AIG-Insurance.webp"),
        "Cashless Garages": 5400,
        "Claims Settled": "90%",
        "Starting from": "3,775",
        "Check Premium": "Check Premium",
        link: "/tata-aig-car-insurance",
      },
      {
        "id": "car-7",
        "InsuranceCompany": "United India Car Insurance",
        "ImageURL": require("../assets/insurance_company_logos/United-India-Insurance.webp"),
        "Cashless Garages": 3100,
        "Claims Settled": "97%",
        "Starting from": "2,795",
        "Check Premium": "Check Premium",
        link: "/united-india-car-insurance",

      },
      {
        "id": "car-8",
        "InsuranceCompany": "Cholamandalam Car Insurance",
        "ImageURL": require("../assets/insurance_company_logos/Cholamandalam-Insurance.webp"),
        "Cashless Garages": 14500,
        "Claims Settled": "95%",
        "Starting from": "2,094",
        "Check Premium": "Check Premium",
        link: "/cholamandalam-car-insurance",

      },
      {
        "id": "car-9",
        "InsuranceCompany": "Universal Sompo Car Insurance",
        "ImageURL": require("../assets/insurance_company_logos/Universal_Sompo-1.webp"),
        "Cashless Garages": 9982,
        "Claims Settled": "91%",
        "Starting from": "3,174",
        "Check Premium": "Check Premium",
        link: "/universal-sompo-car-insurance",

      },
      {
        "id": "car-10",
        "InsuranceCompany": "Reliance Car Insurance",
        "ImageURL": require("../assets/insurance_company_logos/Reliance-Insurance.webp"),
        "Cashless Garages": 8500,
        "Claims Settled": "99%",
        "Starting from": "1,327",
        "Check Premium": "Check Premium",
        link: "/reliance-car-insurance",

      },
      {
        "id": "car-11",
        "InsuranceCompany": "Oriental Car Insurance",
        "ImageURL": require("../assets/insurance_company_logos/Oriental-Insurance.webp"),
        "Cashless Garages": 3100,
        "Claims Settled": "94%",
        "Starting from": "3,005",
        "Check Premium": "Check Premium",
        link: "/oriental-car-insurance",

      }
      // ... other car insurance companies ...
    ],
    "icon1": BiSolidCarGarage,
    "icon2": AiOutlineSolution,
  },
  "Bikes": {
    "normal": [
      {
        "id": "bike-1",
        "InsuranceCompany": "National Bike Insurance",
        "ImageURL": require("../assets/insurance_company_logos/National-Insurance.webp"),
        "Cashless Garages": 3100,
        "Claims Settled": "93%",
        "Starting from": "714",
        "Check Premium": "Check Premium"
      },
      {
        "id": "bike-2",
        "InsuranceCompany": "New India Assurance Bike Insurance",
        "ImageURL": require("../assets/insurance_company_logos/New-India-Assurance-Insurance.webp"),
        "Cashless Garages": 3000,
        "Claims Settled": "96%",
        "Starting from": "714",
        "Check Premium": "Check Premium"
      },
      {
        "id": "bike-3",
        "InsuranceCompany": "TATA AIG Bike Insurance",
        "ImageURL": require("../assets/insurance_company_logos/TATA-AIG-Insurance.webp"),
        "Cashless Garages": 7500,
        "Claims Settled": "96%",
        "Starting from": "714",
        "Check Premium": "Check Premium"
      },
      {
        "id": "bike-4",
        "InsuranceCompany": "United India Bike Insurance",
        "ImageURL": require("../assets/insurance_company_logos/United-India-Insurance.webp"),
        "Cashless Garages": 3100,
        "Claims Settled": "97%",
        "Starting from": "814",
        "Check Premium": "Check Premium"
      }
    ],
    "see_more": [
      {
        "id": "bike-5",
        "InsuranceCompany": "Bajaj Allianz Bike Insurance",
        "ImageURL": require("../assets/insurance_company_logos/Bajaj-Allianz-Insurance.webp"),
        "Cashless Garages": 6500,
        "Claims Settled": "98%",
        "Starting from": "885",
        "Check Premium": "Check Premium"
      },
      {
        "id": "bike-6",
        "InsuranceCompany": "HDFC ERGO Bike Insurance",
        "ImageURL": require("../assets/insurance_company_logos/HDFC-ERGO-Insurance.webp"),
        "Cashless Garages": 2000,
        "Claims Settled": "100%",
        "Starting from": "812",
        "Check Premium": "Check Premium"
      },
      {
        "id": "bike-7",
        "InsuranceCompany": "IFFCO Tokio Bike Insurance",
        "ImageURL": require("../assets/insurance_company_logos/IFFCO-Tokio-Insurance.webp"),
        "Cashless Garages": 4300,
        "Claims Settled": "89%",
        "Starting from": "792",
        "Check Premium": "Check Premium"
      },
      {
        "id": "bike-8",
        "InsuranceCompany": "ICICI Lombard Bike Insurance",
        "ImageURL": require("../assets/insurance_company_logos/ICICI-Lombard-Insurance.webp"),
        "Cashless Garages": 12000,
        "Claims Settled": "100%",
        "Starting from": "838",
        "Check Premium": "Check Premium"
      },
      {
        "id": "bike-9",
        "InsuranceCompany": "Reliance Bike Insurance",
        "ImageURL": require("../assets/insurance_company_logos/Reliance-Insurance.webp"),
        "Cashless Garages": 8500,
        "Claims Settled": "99%",
        "Starting from": "714",
        "Check Premium": "Check Premium"
      },
      {
        "id": "bike-10",
        "InsuranceCompany": "Oriental Bike Insurance",
        "ImageURL": require("../assets/insurance_company_logos/Oriental-Insurance.webp"),
        "Cashless Garages": 3100,
        "Claims Settled": "99%",
        "Starting from": "1005",
        "Check Premium": "Check Premium"
      }
      // ... other bike insurance companies ...
    ]
  },
  "Health": {
    "normal": [
      {
        "id": "health-1",
        "InsuranceCompany": "Active Assure - Diamond Health Insurance",
        "ImageURL": require("../assets/insurance_company_logos/Aviva_Insurance.webp"),
        "Coverage": "3lakh - 50lakh",
        "Age eligibility": "91 days onwards",
        "Starting from": "7,149",
        "Check Premium": "Check Premium"
      },
      {
        "id": "health-2",
        "InsuranceCompany": "Arogya Plus Health Insurance",
        "ImageURL": require("../assets/insurance_company_logos/TATA-AIG-Insurance.webp"),
        "Coverage": "3lakh - 50lakh",
        "Age eligibility": "91 days onwards",
        "Starting from": "7,149",
        "Check Premium": "Check Premium"
      },
      {
        "id": "health-3",
        "InsuranceCompany": "Arogya Sanjeevani - General Insurance",
        "ImageURL": require("../assets/insurance_company_logos/National-Insurance.webp"),
        "Coverage": "3lakh - 50lakh",
        "Age eligibility": "91 days onwards",
        "Starting from": "7,149",
        "Check Premium": "Check Premium"
      },
      {
        "id": "health-4",
        "InsuranceCompany": "Arogya Supreme",
        "ImageURL": require("../assets/insurance_company_logos/New-India-Assurance-Insurance.webp"),
        "Coverage": "3lakh - 50lakh",
        "Age eligibility": "91 days onwards",
        "Starting from": "7,149",
        "Check Premium": "Check Premium"
      }
    ],
    "see_more": [
      {
        "id": "health-5",
        "InsuranceCompany": "Corona Kavach - General Health Insurance",
        "ImageURL": require("../assets/insurance_company_logos/SBI-Insurance.webp"),
        "Coverage": "3lakh - 50lakh",
        "Age eligibility": "91 days onwards",
        "Starting from": "7,149",
        "Check Premium": "Check Premium"
      },
      {
        "id": "health-6",
        "InsuranceCompany": "ICICI Pru iProtect Smart Health Insurance",
        "ImageURL": require("../assets/insurance_company_logos/ICICI-Lombard-Insurance.webp"),
        "Coverage": "3lakh - 50lakh",
        "Age eligibility": "91 days onwards",
        "Starting from": "7,149",
        "Check Premium": "Check Premium"
      },
      {
        "id": "health-7",
        "InsuranceCompany": "LIC Health Insurance",
        "ImageURL": require("../assets/insurance_company_logos/HDFC_Life_Insurance.webp"),
        "Coverage": "3lakh - 50lakh",
        "Age eligibility": "91 days onwards",
        "Starting from": "7,149",
        "Check Premium": "Check Premium"
      },
      {
        "id": "health-8",
        "InsuranceCompany": "Max Bupa Health Companion Family First",
        "ImageURL": require("../assets/insurance_company_logos/Manipal-Cigna-Insurance.webp"),
        "Coverage": "3lakh - 50lakh",
        "Age eligibility": "91 days onwards",
        "Starting from": "7,149",
        "Check Premium": "Check Premium"
      },
      {
        "id": "health-9",
        "InsuranceCompany": "National Health Insurance",
        "ImageURL": require("../assets/insurance_company_logos/National-Insurance.webp"),
        "Coverage": "3lakh - 50lakh",
        "Age eligibility": "91 days onwards",
        "Starting from": "7,149",
        "Check Premium": "Check Premium"
      },
      {
        "id": "health-10",
        "InsuranceCompany": "New India Assurance Health Insurance",
        "ImageURL": require("../assets/insurance_company_logos/New-India-Assurance-Insurance.webp"),
        "Coverage": "3lakh - 50lakh",
        "Age eligibility": "91 days onwards",
        "Starting from": "7,149",
        "Check Premium": "Check Premium"
      }
      // ... other health insurance companies ...
    ]
  },
  "Life": {
"normal": [
  {
    "id": "life-1",
    "InsuranceCompany": "ICICI Pru iProtect Smart Life Insurance",
    "ImageURL": require("../assets/insurance_company_logos/ICICI_Pru_Insurance.webp"),
    "Coverage": "50lakh - 1crore",
    "Age eligibility": "18 years onwards",
    "Starting from": "5,000",
    "Check Premium": "Check Premium"
  },
  {
    "id": "life-2",
    "InsuranceCompany": "LIC Jeevan Amar Life Insurance",
    "ImageURL": require("../assets/insurance_company_logos/HDFC_Life_Insurance.webp"),
    "Coverage": "25lakh - 2crore",
    "Age eligibility": "18 years onwards",
    "Starting from": "6,500",
    "Check Premium": "Check Premium"
  },
  {
    "id": "life-3",
    "InsuranceCompany": "HDFC Life Click 2 Protect 3D Plus",
    "ImageURL": require("../assets/insurance_company_logos/HDFC_Life_Insurance.webp"),
    "Coverage": "50lakh - 1.5crore",
    "Age eligibility": "18 years onwards",
    "Starting from": "4,500",
    "Check Premium": "Check Premium"
  },
  {
    "id": "life-4",
    "InsuranceCompany": "SBI Life eShield Next",
    "ImageURL": require("../assets/insurance_company_logos/SBI-Insurance.webp"),
    "Coverage": "50lakh - 2crore",
    "Age eligibility": "18 years onwards",
    "Starting from": "5,200",
    "Check Premium": "Check Premium"
  }
],
"see_more": [
  {
    "id": "life-5",
    "InsuranceCompany": "Max Life Smart Secure Plus",
    "ImageURL": require("../assets/insurance_company_logos/HDFC_Life_Insurance.webp"),
    "Coverage": "50lakh - 1crore",
    "Age eligibility": "18 years onwards",
    "Starting from": "5,300",
    "Check Premium": "Check Premium"
  },
  {
    "id": "life-6",
    "InsuranceCompany": "TATA AIA Sampoorna Raksha",
    "ImageURL": require("../assets/insurance_company_logos/TATA-AIG-Insurance.webp"),
    "Coverage": "25lakh - 1crore",
    "Age eligibility": "18 years onwards",
    "Starting from": "5,000",
    "Check Premium": "Check Premium"
  },
  {
    "id": "life-7",
    "InsuranceCompany": "Bajaj Allianz Life Smart Protect Goal",
    "ImageURL": require("../assets/insurance_company_logos/Bajaj-Allianz-Insurance.webp"),
    "Coverage": "50lakh - 1crore",
    "Age eligibility": "18 years onwards",
    "Starting from": "4,800",
    "Check Premium": "Check Premium"
  },
  {
    "id": "life-8",
    "InsuranceCompany": "Reliance Nippon Life Digi-Term",
    "ImageURL": require("../assets/insurance_company_logos/Reliance-Insurance.webp"),
    "Coverage": "50lakh - 1.5crore",
    "Age eligibility": "18 years onwards",
    "Starting from": "5,000",
    "Check Premium": "Check Premium"
  },
  {
    "id": "life-9",
    "InsuranceCompany": "PNB MetLife Mera Term Plan Plus",
    "ImageURL": require("../assets/insurance_company_logos/ICICI_Pru_Insurance.webp"),
    "Coverage": "25lakh - 2crore",
    "Age eligibility": "18 years onwards",
    "Starting from": "6,000",
    "Check Premium": "Check Premium"
  },
  {
    "id": "life-10",
    "InsuranceCompany": "Kotak e-Term Plan",
    "ImageURL": require("../assets/insurance_company_logos/Kotak-General-Insurance.webp"),
    "Coverage": "50lakh - 1crore",
    "Age eligibility": "18 years onwards",
    "Starting from": "5,500",
    "Check Premium": "Check Premium"
  }
  // ... other life insurance companies ...
]
}
};

export const testimonialsData = {

  car_testimonials: [
  {
      name: "Chandana Rathod",
      role: "Digital Marketing",
      testimonial: "I highly recommend Legacy Insurance Brokers for their exceptional and reliable service.",
      image: require('../assets/TestimonialUsers/ChandanaRathod.png'),
      altText: "chandana_rathod_img"
  },
  {
      name: "Anand Tiwari",
      role: "AI/ML",
      testimonial: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.",
      image: require('../assets/TestimonialUsers/AnandTiwari_img.png'),
      altText: "anand_tiwari_img"
  },
  {
      name: "Durga Prasad",
      role: "Graphic Designer",
      testimonial: "Legacy Insurance Brokers made the process of getting insurance effortless and stress-free.",
      image: require('../assets/TestimonialUsers/DurgaPrasad.png'),
      altText: "durgaprasad_img"
  },
  {
      name: "Indraja",
      role: "Marketing Specialist",
      testimonial: "Their team is professional, attentive, and always ready to help with any questions.",
      image: require('../assets/TestimonialUsers/Indraja.png'),
      altText: "indraja_img"
  },
  {
      name: "Jhon Doe",
      role: "Product Manager",
      testimonial: "The policies offered are comprehensive and tailored to my specific needs.",
      image: require('../assets/TestimonialUsers/JohnDoe.png'),
      altText: "jhondoe_img"
  },
  {
      name: "Michel Davis",
      role: "Data Analyst",
      testimonial: "I am impressed with the customer service and the range of options provided by Legacy Insurance Brokers.",
      image: require('../assets/TestimonialUsers/MichelDavis.png'),
      altText: "michel_davis_img"
  },
  {
      name: "Prasanna kumari",
      role: "Sales Executive",
      testimonial: "The ease of communication and quick response times have made my experience with Legacy Insurance Brokers exceptional.",
      image: require('../assets/TestimonialUsers/PrasannaKumari.png'),
      altText: "prasanna_kumari_img"
  },
  ],
  bike_testimonials: [
    {
        Name: "John Doe",
        Role: "IT Professional",
        testimonial: "I highly recommend Legacy Insurance Brokers for their exceptional and reliable service. As someone who commutes daily by bike, their coverage provides the security I need on the busy roads of the city.",
        image: "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(3).webp",
    },
    {
        Name: "Liam White",
        Role: "Doctor",
        testimonial: "Legacy Insurance Brokers offer a service that is both reliable and tailored to bikers. As a doctor with a hectic schedule, I trust them to keep my bike protected so I can focus on my patients.",
        image: "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(7).webp", 
    
    },
    {
        Name: "Mia Anderson",
        Role: "Entrepreneur",
        testimonial: "I highly recommend Legacy Insurance Brokers for their outstanding service. Being an entrepreneur, my bike is essential for quick and efficient travel.",
        image: "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(1).webp",
    },
    {
        Name: "Ryan Thompson",
        Role: "Architect",
        testimonial: "Legacy Insurance Brokers truly understand the needs of bikers. As an architect, I’m constantly on the move, and their reliable service gives me peace of mind on every ride.",
        image: "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(2).webp",
    },
    {
        Name: "David Lee",
        Role: "Software Developer",
        testimonial: "I highly recommend Legacy Insurance Brokers. With a passion for weekend rides, I appreciate their comprehensive bike coverage",
        image: "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(4).webp", 
    },
    {
        Name: "Alex Johnson",
        Role: "Financial Analyst",
        testimonial: "Legacy Insurance Brokers offer top-notch service. Their clear and comprehensive coverage options for my bike ensure that I’m protected at all times.",
        image: "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(5).webp",
    },
    {
        Name: "Emily Davis",
        Role: "Chartered Accountant",
        testimonial: "I trust Legacy Insurance Brokers to keep my bike protected. Their service is efficient and tailored, providing the security I need to focus on my work and enjoy my rides.",
        image: "https://mdbcdn.b-cdn.net/img/Photos/Avatars/img%20(6).webp",
    },
  ],
};

export const InsuranceTopIntroSection = {
  carIntroSection: {
    title: {
      part1: "What is",
      part2: "Car Insurance?"
    },
    description: [
      "Car insurance is an agreement between a vehicle owner and an insurance company, where the insurer promises to provide financial support to the owner in the event of damage to the car due to unforeseen circumstances.",
      "This coverage typically includes protection against accidents, theft, and various types of damage. Depending on the policy, it may also offer additional benefits such as roadside assistance, rental car coverage, and protection against liability for injuries or property damage caused to others."
    ],
    image: require("../assets/Car/car_intro_land.webp"),
    altText: "Bike Top Intro IMG",
  
  },
  bikeIntroSection: {
    title: {
      part1: "What is",
      part2: "Bike Insurance?"
    },
    description: [
      "Bike insurance is an agreement between a bike owner and an insurance company, where the insurer promises to provide financial support to the owner in the event of damage to the bike due to unforeseen circumstances.",
      "This coverage typically includes protection against accidents, theft, and various types of damage. Depending on the policy, it may also offer additional benefits such as roadside assistance, coverage for a temporary replacement bike, and protection against liability for injuries or property damage caused to others."
    ],
    image: require('../assets/Bike/bike_intro_land.webp'),
  
  },
  healthIntroSection: {
    title: {
      part1: "What is",
      part2: "Health Insurance?"
    },
    description: [
      "Health insurance is an agreement between an individual and an insurance company, where the insurer promises to provide financial support for medical expenses in the event of illness, injury, or other unforeseen health issues.",
      "This coverage typically includes protection against a range of medical expenses, including hospitalization, surgeries, and treatment for various illnesses. Depending on the policy, it may also offer additional benefits such as preventive care, coverage for prescription medications, and protection against liability for medical expenses or damages caused to others."
    ],
    image: require("../assets/Health/health_intro_land.webp"),
  
  
  },
  lifeIntroSection: {
    title: {
      part1: "What is",
      part2: "Life Insurance?"
    },
    description: [
      "Life insurance is an agreement between an individual and an insurance company, where the insurer promises to provide financial support to the policyholder's beneficiaries in the event of the policyholder's death or other covered life events.",
      "This coverage typically includes financial protection for your loved ones in the event of your death, ensuring they are supported during difficult times. Depending on the policy, it may also offer additional benefits such as living benefits for terminal illness, accidental death coverage, and options for investment or cash value accumulation."
    ],
    image: require("../assets/Life/life_intro_land.webp"),
  
  
  },
}


export const ExploreSectionData = {
car: {
  exploreIntro: {
    title: {
      part1: "Explore Our",
      part2: "Car Insurance",
      part3: "Options",
    },
    description: "Discover the perfect car insurance plan for your needs. From basic protection to comprehensive coverage, we offer tailored solutions to safeguard your vehicle and ensure peace of mind on the road.",
  },
  sections: [
    {
      title: "Comprehensive Insurance",
      description: "Comprehensive insurance offers extensive protection for your vehicle, covering damages from accidents, theft, vandalism, and natural disasters. It also includes third-party liability, ensuring peace of mind in all scenarios.",
      features: [
        "Includes protection against theft, vandalism, natural disasters, and accidental damage.",
        "Covers damages and injuries to others caused by you.",
        "Often includes repair or replacement of damaged windows.",
        "May offer roadside support and towing services.",
      ],
      image: require("../assets/Car/insurancetype1.webp"), // Replace with the actual image path
      altText: "Car Comprehensive Insurance IMG",
    },
    {
      title: "Third-Party Insurance",
      description: "Third-party insurance covers damages and injuries caused to others in an accident where you are at fault. It’s a legal requirement in many places and provides essential liability protection, but does not cover your own vehicle.",
      features: [
        "Meets the minimum legal coverage required in many regions.",
        "Covers bodily injury and property damage caused to others in an accident where you are at fault.",
        "Does not cover damage to your own car or personal injuries.",
        "Provides essential protection without additional perks.",
      ],
      image: require("../assets/Car/insurancetype2.webp"), // Replace with the actual image path
      altText: "Car Third Party Insurance IMG",
    },
    {
      title: "Own Damage Insurance",
      description: "Own damage insurance specifically covers repairs and damages to your vehicle from accidents, vandalism, or other incidents. It is often combined with third-party insurance to offer complete protection for your car.",
      features: [
        "Covers repairs and damages to your own vehicle from accidents, vandalism, or specified risks.",
        "Often available as an add-on to third-party policies for enhanced coverage.",
        "Includes protection for damages resulting from collisions, regardless of fault.",
        "Typically covers losses from theft and fire-related incidents.",
      ],
      image: require("../assets/Car/insurancetype3.webp"), // Replace with the actual image path
      altText: "Car Own Damage Insurance IMG",
    },
  ],
},
bike: {
  exploreIntro: {
    title: {
      part1: "Explore Our",
      part2: "Bike Insurance",
      part3: "Options",
    },
    description: "Discover the perfect bike insurance plan for your needs. From basic protection to comprehensive coverage, we offer tailored solutions to safeguard your bike and ensure peace of mind on every ride.",
  },
  sections: [
    {
      title: "Comprehensive Bike Insurance",
      description: "Protect your bike against a wide range of risks with our comprehensive insurance plan. This coverage includes protection from accidents, theft, fire, and natural disasters, ensuring that your bike is always secure, no matter the situation.",
      features: [
        "Coverage for accidental damage.",
        "Protection against theft and vandalism.",
        "Coverage for natural disasters and fire.",
        "Optional add-ons like zero depreciation and engine protection",
      ],
      image: require("../assets/Bike/bike_img_land.webp"), 
      altText: "Bike Comprehensive Insurance IMG",
    },
    {
      title: "Third-Party Liability Bike Insurance",
      description: "Stay legally compliant and protect yourself from financial liabilities with our third-party liability insurance. This plan covers damages to other vehicles, property, or individuals in case of an accident involving your bike.",
      features: [
        "Covers third-party property damage.",
        "Protects against third-party injury or death claims.",
        "Affordable premiums.",
        "Meets legal requirements for riding on public roads.",
      ],
      image: require("../assets/Bike/third_party_bike_insurance.webp"), 
      altText: "Bike Third Party Insurance IMG",
    },
    {
      title: "Own Damage Bike Insurance",
      description: "Secure your bike against unforeseen damages with our own damage insurance. This plan is ideal for those who already have third-party liability coverage but need additional protection for their own bike.",
      features: [
        "Coverage for accidental damages to your bike",
        "Protection against natural calamities like floods and earthquakes",
        "Coverage for fire and theft",
        "Add-ons available for enhanced protection",
      ],
      image: require("../assets/Bike/own_damage_bike_insurance.webp"), // Replace with the actual image path
      altText: "Bike Own Damage Insurance IMG",
    },
  ],
},
health: {
  exploreIntro: {
    title: {
      part1: "Protect Your Health with Comprehensive",
      part2: "Insurance Plans",
      part3: "",
    },
    description: "Choosing the right health insurance is crucial for ensuring your well-being and financial security. We offer a range of popular health insurance plans designed to meet your specific needs. Whether you're looking for essential coverage or comprehensive protection, our plans provide peace of mind and access to quality healthcare.",
  },
  sections: [
    {
      title: "Basic HealthGuard Plan",
      description: "The Basic HealthGuard Plan offers essential coverage for individuals and families, ensuring access to necessary medical care without breaking the bank. Ideal for those looking for affordable protection.",
      features: [
        "Coverage for hospitalization and surgeries.",
        "Access to a wide network of hospitals and clinics",
        "Outpatient care and diagnostic services",
        "Annual health check-ups included",
      ],
      image: require("../assets/Health/Basic HealthGuard Plan.webp"), // Replace with the actual image path
      altText: "Health Guard Basic Insurance IMG",
    },
    {
      title: "Comprehensive Care Plan",
      description: "The Comprehensive Care Plan provides extensive coverage, including specialized treatments and preventive care. Perfect for individuals and families seeking a more robust health insurance solution.",
      features: [
        "Coverage for major and minor surgeries",
        "Access to specialists and advanced medical treatments",
        "Prescription drug coverage",
        "Maternity and newborn care benefits",
      ],
      image: require("../assets/Health/Comprehensive Care Plan.webp"), // Replace with the actual image path
      altText: "Health Comprehensive Care Plan IMG",
    },
    {
      title: "Family Wellness Plan",
      description: "The Family Wellness Plan is designed to protect your entire family's health, offering coverage that addresses the diverse needs of all family members. It's the ideal plan for families looking for inclusive health insurance.",
      features: [
        "Coverage for all family members under a single policy",
        "Pediatric care and vaccinations for children",
        "Wellness programs and preventive care services",
        "Emergency medical services and ambulance coverage",
      ],
      image: require("../assets/Health/Family Wellness Plan.webp"), // Replace with the actual image path
      altText: "Family Wellness Plan IMG",
    },
  ],
},
life: {
  exploreIntro: {
    title: {
      part1: "Safeguard Your Future with All-Inclusive",
      part2: "Life Insurance Plans",
      part3: "",
    },
    description: "Ensure your loved ones are financially secure with our all-inclusive life insurance plans. Our coverage provides peace of mind, offering protection against unforeseen life events and supporting your family's future needs. With customizable options, you can tailor a plan that suits your unique circumstances, ensuring stability and security for those you care about.",
  },
  sections: [
    {
      title: "Term Life Assurance",
      description: "Term Life Assurance offers straightforward and affordable protection for a specified period. It's ideal for individuals seeking substantial coverage at a lower cost.",
      features: [
        "Coverage for hospitalization and surgeries.",
        "Access to a wide network of hospitals and clinics",
        "Outpatient care and diagnostic services",
        "Annual health check-ups included",
      ],
      image: require("../assets/Life/Term_Life_Insurance.webp"), // Replace with the actual image path
    },
    {
      title: "Comprehensive Care Plan",
      description: "The Comprehensive Care Plan provides extensive coverage, including specialized treatments and preventive care. Perfect for individuals and families seeking a more robust health insurance solution.",
      features: [
        "Coverage for major and minor surgeries",
        "Access to specialists and advanced medical treatments",
        "Prescription drug coverage",
        "Maternity and newborn care benefits",
      ],
      image: require("../assets/Life/Child_Life_Insurance.webp"), // Replace with the actual image path
    },
    {
      title: "Family Wellness Plan",
      description: "The Family Wellness Plan is designed to protect your entire family's health, offering coverage that addresses the diverse needs of all family members. It's the ideal plan for families looking for inclusive health insurance.",
      features: [
        "Coverage for all family members under a single policy",
        "Pediatric care and vaccinations for children",
        "Wellness programs and preventive care services",
        "Emergency medical services and ambulance coverage",
      ],
      image: require("../assets/Life/Pension_Life_Insurance.webp"), // Replace with the actual image path
    },
  ],
},
};
export const services = [
  {
    id: 1,
    title: "Health Insurance",
    description: "Health insurance plans that cover medical expenses, and emergencies, safeguarding your well-being and financial security.",
    icon: FaHeartbeat, 
    link: "#",
  },
  {
    id: 2,
    title: "Life Insurance",
    description: "Life insurance policies designed to provide financial security for your loved ones, ensuring peace of mind for the future.",
    icon: BsUmbrella, 
    link: "#",
  },
];
export const pricingPlans = [
  {
    price: "$3500 *",
    title: "Basic Plans",
    description: "Essential coverage for your bike at an affordable rate.",
    coverages: [
      "10GB Storage",
      "Basic Support",
      "Access to all features",
    ],
    keyFeatures: [
      "24/7 Customer Service",
      "No setup fees",
      "Cancel anytime"
    ],
    background: '#E6F6FA',
    color: '#000',
  },
  {
    price: "$6500 *",
    title: "Standard Plan",
    description: "Comprehensive protection with added benefits for peace of mind.",
    coverages: [
      "50GB Storage",
      "Priority Support",
      "Advanced features",
    ],
    keyFeatures: [
      "Dedicated account manager",
      "Free onboarding session",
      "Exclusive resources"
    ],
    background: '#024656',
    color: '#fff',
  },
  {
    price: "$8500 *",
    title: "Premium Plan",
    description: "Top-tier coverage with extensive features for complete security.",
    coverages: [
      "Unlimited Storage",
      "24/7 Priority Support",
      "All premium features",
    ],
    keyFeatures: [
      "Personalized consultation",
      "Advanced analytics",
      "Custom integrations"
    ],
    background: '#E6F6FA',
    color: '#000',
  }
];

export const serviceList  = {
  Home: [
    {
      icon: <BsUmbrella/>, 
      title: 'Life Insurance',
      description: 'Life insurance policies designed to provide financial security for your loved ones, ensuring peace of mind for the future.',
      path: '/life'
    },
    {
      icon: <FaHeartPulse/>, 
      title: 'Health Insurance',
      description: 'Health insurance plans that cover medical expenses, and emergencies, safeguarding your well-being and financial security.',
      path: '/health'
    },
    {
      icon: <TfiCar/>, 
      title: 'Car Insurance',
      description: 'Comprehensive car insurance coverage ensures protection against accidents, theft, and damage, offering peace of mind.',
      path: '/car'
    },
    {
      icon: <FaMotorcycle/>, 
      title: 'Bike Insurance',
      description: 'Reliable bike insurance providing coverage for accidents, theft, and damage, ensuring your ride is always secure and protected.',
      path: '/bike'
    }
  ],
  Car: [
    
    {
      icon: <FaMotorcycle/>, 
      title: 'Bike Insurance',
      description: 'Reliable bike insurance providing coverage for accidents, theft, and damage, ensuring your ride is always secure and protected.',
      path: '/bike'
    },
    {
      icon: <FaHeartPulse/>, 
      title: 'Health Insurance',
      description: 'Health insurance plans that cover medical expenses, and emergencies, safeguarding your well-being and financial security.',
      path: '/health'
    }
  ],
  Bike: [
    {
      icon: <TfiCar/>, 
      title: 'Car Insurance',
      description: 'Comprehensive car insurance coverage ensures protection against accidents, theft, and damage, offering peace of mind.',
      path: '/car'
    },
    {
      icon: <FaHeartPulse/>, 
      title: 'Health Insurance',
      description: 'Health insurance plans that cover medical expenses, and emergencies, safeguarding your well-being and financial security.',
      path: '/health'
    }
  ],
  Health: [
    {
      icon: <TfiCar/>, 
      title: 'Car Insurance',
      description: 'Comprehensive car insurance coverage ensures protection against accidents, theft, and damage, offering peace of mind.',
      path: '/car'
    },
    {
      icon: <FaMotorcycle/>, 
      title: 'Bike Insurance',
      description: 'Reliable bike insurance providing coverage for accidents, theft, and damage, ensuring your ride is always secure and protected.',
      path: '/bike'
    },
    {
      icon: <BsUmbrella/>, 
      title: 'Life Insurance',
      description: 'Life insurance policies designed to provide financial security for your loved ones, ensuring peace of mind for the future.',
      path: '/life'
    },
    {
      icon: <FaHeartPulse/>, 
      title: 'Health Insurance',
      description: 'Health insurance plans that cover medical expenses, and emergencies, safeguarding your well-being and financial security.',
      path: '/health'
    }
  ],
  Life: [
    {
      icon: <TfiCar/>, 
      title: 'Car Insurance',
      description: 'Comprehensive car insurance coverage ensures protection against accidents, theft, and damage, offering peace of mind.',
      path: '/car'
    },
    {
      icon: <FaMotorcycle/>, 
      title: 'Bike Insurance',
      description: 'Reliable bike insurance providing coverage for accidents, theft, and damage, ensuring your ride is always secure and protected.',
      path: '/bike'
    },
  ]
};

export const products = {
  Car: [
    { name: 'Third Party', logoImg: require('../assets/InsuranceTypes/CarBikeThirdParty.png'), altText: 'Car third party' },
    { name: 'Comprehensive', logoImg: require('../assets/InsuranceTypes/CarBikeComprehensive.png'), altText: 'Car Comprehensive party' },
    { name: 'Own Damage', logoImg: require('../assets/InsuranceTypes/CarOwnDamage.png'), altText: 'Car Own Damage' },

  ],
  Bike: [
    { name: 'Third Party', logoImg: require('../assets/InsuranceTypes/CarBikeThirdParty.png'), altText: 'Bike third party' },
    { name: 'Comprehensive', logoImg: require('../assets/InsuranceTypes/CarBikeComprehensive.png'), altText: 'Bike Comprehensive party' },
    { name: 'Own Damage', logoImg: require('../assets/InsuranceTypes/BikeOwnDamage.png'), altText: 'Bike Own Damage' }
  ],
  Health: [
    { name: 'Basic Health Guard', logoImg: require('../assets/InsuranceTypes/BasicHealthGuard.png'), altText: 'Basic Health Guard' },
    { name: 'Comprehensive Care', logoImg: require('../assets/InsuranceTypes/CarBikeComprehensive.png'), altText: 'Comprehensive Care' },
    { name: 'Family Wellness', logoImg: require('../assets/InsuranceTypes/FamilyWellness.png'), altText: 'Family Wellness' }
  ],
  Life: [
    { name: 'Term Life', logoImg: require('../assets/InsuranceTypes/TermLife.png'), altText: 'Term Life' },
    { name: 'Child Life', logoImg: require('../assets/InsuranceTypes/Child Life.png'), altText: 'Child Life' },
    { name: 'Pension Life', logoImg: require('../assets/InsuranceTypes/Pension Life.png'), altText: 'Pension Life' }
  ]
};

export const whyUsContent = {
  subHeading: "Why Choose Us",
  heading: "Why Legacy Insurance Brokers Stand Out in the Insurance Industry?",
  features: [
    {
      img: require("../assets/Home/choose_img_1.webp"),
      title: "Extensive Experience and Expertise",
      description:
        "Years of industry experience and specialized knowledge enable us to deliver superior insurance solutions and navigate complex needs effectively."
    },
    {
      img: require("../assets/Home/choose_img-2.webp"),
      title: "Broad Coverage Options",
      description:
        "Offering a wide range of insurance plans to meet diverse needs, ensuring comprehensive protection for auto, home, health, and life."
    },
    {
      img: require("../assets/Home/choose_img-3.webp"),
      title: "Customer-Centric Service",
      description:
        "Dedicated to personalized service, we prioritize your needs, providing tailored solutions and responsive support to enhance your insurance experience."
    }
  ],
  imageSrc: require("../assets/Home/why_choose_img.webp"),
  customerSatisfactionSrc: require("../assets/Home/customersatisfaction_img.webp"),
};

export const heroBannerContent = {
  heading: "Secure Your Future",
  subHeading: "Insurance Solutions by Legacy Insurance Brokers: Comprehensive coverage for auto, home, life, and business, ensuring security and peace of mind.",
  buttonText: "Get a Quote",
  contactInfo: "+91 891-943-9603",
  mainImage: require("../assets/Home/family_pic.webp"),
  backgroundImage: require("../assets/Home/family_bg_img.webp"),
};
export const recordStats = [
  { number: '3L+', text: 'Trained Advisors' },
  { number: '45L+', text: 'Happy Customers' },
  { number: '45+', text: 'Insurance Partners' },
  { number: '1 Cr+', text: 'Policies Sold' },
];

export const faqContent = {
  subTitle: "FAQs",
  title: "Answers to Your Questions",
  faqs: [
    {
      question: "What is Bike insurance?",
      answer: "Bike insurance provides financial protection for your motorcycle and its rider. It covers damage from accidents, theft, and other risks, while also offering liability coverage for injuries or damages caused to others."
    },
    {
      question: "Why do I need Bike insurance?",
      answer: "Bike insurance is essential to cover unexpected damages or accidents. It protects you financially and ensures you can handle liabilities arising from injuries or damages to others."
    },
    {
      question: "What are the different types of Bike insurance?",
      answer: "There are various types of bike insurance, including comprehensive coverage, third-party liability coverage, and personal accident cover, each offering different levels of protection."
    },
    {
      question: "How much Bike insurance coverage do I need?",
      answer: "The amount of bike insurance coverage you need depends on various factors, including the value of your bike, your usage, and personal preferences. It's advisable to get enough coverage to handle significant repairs or replacements and liabilities."
    }
  ],
  footerText: "When selecting a bike insurance policy online, it's crucial to evaluate several key factors to ensure you get the best coverage for your needs. First, determine the type of plan that suits you, such as a comprehensive policy for broader protection or third-party insurance for basic coverage. Next, use online comparison tools to assess different policies based on features, premiums, and value."
};


export const WorkProcessContent = {
  home_work_process_1: require("../assets/Home/work_process_img_1.webp"),
  home_work_process_2: require("../assets/Home/work_process_img_2.webp"),
  home_work_process_3: require("../assets/Home/work_process_img_3.webp"),
  work_process_steps: {
    step1: {
      title: "Consultation and Assessment",
      description: [
        {
          text: "Initial Meeting",
          detail: "During the initial meeting, we discuss your insurance needs, assess risks, and tailor solutions to ensure comprehensive coverage and satisfaction."
        },
        {
          text: "Assessment of Needs",
          detail: "Assessment of needs involves evaluating your specific requirements, risks, and goals to customize insurance solutions that offer optimal protection and value."
        }
      ]
    },
    step2: {
      title: "Customized Plan Selection",
      description: [
        {
          text: "Plan Options Presented",
          detail: "Plan options presented showcase tailored insurance solutions, highlighting coverage details and benefits to help you choose the best fit for your needs."
        },
        {
          text: "Review and Decision",
          detail: "Review and decision involve evaluating plan details, addressing any questions, and making an informed choice to secure the most suitable coverage."
        }
      ]
    },
    step3: {
      title: "Enrollment and Coverage Activation",
      description: [
        {
          text: "Enrollment Process",
          detail: "Complete the necessary paperwork and provide required information to finalize your insurance policy and begin the enrollment process."
        },
        {
          text: "Coverage Activation",
          detail: "After enrollment, your coverage is activated, ensuring immediate protection and access to benefits as outlined in your chosen policy."
        }
      ]
    }
  }
};
// contact us
export const contactDetails = {

  imageSrc: require("../assets/Contact/getintouch_img.webp"),
};

//all services page

export const AllServices = [
  {
    id: 1,
    icon: <BsUmbrella/>, 
    title: 'Life Insurance',
    description: 'Life insurance policies designed to provide financial security for your loved ones.'
  },
  {
    id: 2,
    icon: <FaHeartPulse/>,
    title: 'Health Insurance',
    description: 'Health insurance plans that cover medical expenses, safeguarding your well-being.'
  },
  {
    id: 3,
    icon: <TfiCar/>,
    title: 'Car Insurance',
    description: 'Comprehensive car insurance ensures protection against accidents and theft.'
  },
  {
    id: 4,
    icon: <FaMotorcycle/>,
    title: 'Bike Insurance',
    description: 'Reliable bike insurance offering coverage for accidents and theft.'
  }
];
export const valueContent = {
  sectionTitle: "Our Value",
  heading: "Driving Insurance with Integrity",
  values: [
    {
      title: "Integrity",
      description:
        "We prioritize ethical practices and transparency in our insurance services, ensuring trust and long-term client relationships through honest and responsible actions.",
      image: require("../assets/OurValue/img-1.webp"),
    },
    {
      title: "Innovation",
      description:
        "We embrace cutting-edge technologies and forward-thinking strategies to provide advanced insurance solutions that adapt to changing client needs and industry trends.",
      image: require("../assets/OurValue/img-2.webp"),
    },
    {
      title: "Reliability",
      description:
        "We are committed to delivering consistent, dependable service, ensuring our clients can trust us to protect their interests with unwavering support and responsiveness.",
      image: require("../assets/OurValue/img-3.webp"),
    },
  ],
};
// Our Mission Section

export const missionContent = {
  sectionTitle: "OUR MISSION",
  heading: "Making a Difference with Legacy Insurance Brokers",
  items: [
    {
      number: "01",
      title: "Customer-Centric Focus",
      description: "Tailoring insurance solutions to align with client needs, enhancing satisfaction, trust, loyalty, and personalized service."
    },
    {
      number: "02",
      title: "Commitment to Integrity",
      description: "Upholding honesty and transparency in insurance practices, fostering trust, ethical conduct, and long-term client relationships."
    },
    {
      number: "03",
      title: "Innovation and Adaptability",
      description: "Embracing new technologies and flexible strategies in insurance to meet evolving client needs and market demands."
    }
  ],
  mainImage: require("../assets/OurMission/our_mission_img-2.webp"),
  overlayImage: require("../assets/OurMission/our_mission_img_1.webp"),
};
export const InsuranceBannerContent = {
  logo: require("../assets/insurance_company_logos/National-Insurance.webp"),
  heading: 'National Insurance Two Wheeler Comprehensive Policy',
  inputPlaceholder: 'Bike Number (TS07FA5099)',
  buttonText: 'Get Quote',
  description: 'Comprehensive coverage for your two-wheeler, ensuring peace of mind on the road.',
  backgroundImage: require("../assets/InsuranceBanner/insurance_banner.png"), 
  infoBoxes: [
    {
      icon: <BsUmbrella />,
      title: 'Cashless Garages',
      value: '3,973+',
    },
    {
      icon: <FaHeartPulse />,
      title: 'Claims Settled',
      value: '98%',
    },
    {
      icon: <TfiCar />,
      title: 'Starting from',
      value: '714/Yr',
    },
  ],
};
