// Services.js
import React from "react";
import LandingSection from "../../components/TopBannerSection";
import { AllServices, constants, testimonialsData } from "../../utils/constants";
import Testimonials from "../../components/TestimonialsSection";
import CallToAction from "../../components/Footer/CallToAction";
import GetStartedForm from "../../components/GetStartedForm";
import ServicesList from "../../components/ServicesList"; // Service list component

const Services = () => {
  return (
    <>
      <LandingSection LandingData={constants.services_landing} />
      <ServicesList services={AllServices} />
      <Testimonials testimonialsData={testimonialsData.bike_testimonials} />
      <CallToAction />
      <GetStartedForm />
    </>
  );
};

export default Services;
