import React from 'react';
import WorkProcess from '../../components/WorkProcessSection';
import AboutSection from '../../components/AboutUsSection';
import InsuranceTabSection from '../../components/InsuranceTabSection';
import WhyChooseUsSection from '../../components/WhyChooseUsSection';
import { testimonialsData, whyUsContent } from '../../utils/constants';
import GetStartedForm from "../../components/GetStartedForm";
import TestimonialsSection from '../../components/TestimonialsSection';
import OurPartners from '../../components/OurPartnersSection';
import ServicesSection from "../../components/ServicesSection";
import {serviceList} from "../../utils/constants"
import HomeBannerSection from "../../components/HomeBannerSection"
import RecordSection from '../../components/RecordsSection';

const HomePage = () => {
  return (
    <>
    <HomeBannerSection/>
    <RecordSection/>
    <AboutSection/>
    <ServicesSection Services={serviceList.Home} />
    <WorkProcess />
    <InsuranceTabSection/>
    <GetStartedForm />
    <WhyChooseUsSection whyUsContent={whyUsContent}/>
    <TestimonialsSection testimonialsData={testimonialsData.car_testimonials}/>
    <OurPartners/>
    </>
  );
}

export default HomePage;