import React from 'react';
import { Formik, Field, Form } from 'formik';
import { Button as BootstrapButton, Col, Row } from 'react-bootstrap';
import styled from 'styled-components';
import * as Yup from 'yup';

// Styled Components
const FormContainer = styled.div`
  padding: 20px;
  border-radius: 8px;
  max-width: 1300px;
  margin: 0 auto;
  text-align: center;

  @media (max-width: 992px) {
    padding: 15px;
    max-width: 100%;
  }
`;

const HeaderText = styled.h2`
  color: #002c3e;
  text-align: center;
  margin-bottom: 20px;

  span {
    color: #ff5a1f;
  }

  @media (max-width: 992px) {
    font-size: 1.5rem;
  }
`;

const CustomField = styled(Field)`
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  margin-bottom: 20px;

  @media (max-width: 992px) {
    padding: 8px;
  }
`;

const ErrorText = styled.div`
  color: red;
  font-size: 0.9rem;
  margin-top: -10px;
  margin-bottom: 10px;
`;

const CustomTextArea = styled.textarea`
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  height: 150px;
  margin-bottom: 20px;

  @media (max-width: 992px) {
    padding: 8px;
    height: 120px;
  }
`;

const CustomSelect = styled.select`
  background-color: #f4f4f4;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  width: 100%;
  margin-bottom: 20px;

  @media (max-width: 992px) {
    padding: 8px;
  }
`;

const CustomButton = styled(BootstrapButton)`
  background-color: #013a4d;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  width: 150px;

  &:hover {
    background-color: #002c3e;
  }

  @media (max-width: 992px) {
    width: 100%;
    font-size: 14px;
    padding: 8px;
  }
`;

// Validation Schema
const validationSchema = Yup.object().shape({
  name: Yup.string().required('Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  phone: Yup.string()
    .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits')
    .required('Phone number is required'),
  insurance: Yup.string().required('Insurance type is required'),
  message: Yup.string().required('Message is required'),
});

const CallBackForm = () => {
  return (
    <Formik
      initialValues={{
        name: '',
        email: '',
        phone: '',
        insurance: '',
        message: ''
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { resetForm }) => {
        console.log(values);
        resetForm(); // Reset form after submission
      }}
    >
      {({ touched, errors, handleChange, handleBlur, values }) => (
        <FormContainer>
          <HeaderText>
            Request A <span>Call Back!</span>
          </HeaderText>
          <Form>
            <Row>
              <Col md={6}>
                <CustomField
                  name="name"
                  type="text"
                  placeholder="Your Name"
                />
                {touched.name && errors.name && <ErrorText>{errors.name}</ErrorText>}
              </Col>
              <Col md={6}>
                <CustomField
                  name="email"
                  type="email"
                  placeholder="Email Address"
                />
                {touched.email && errors.email && <ErrorText>{errors.email}</ErrorText>}
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <CustomField
                  name="phone"
                  type="text"
                  placeholder="Phone Number"
                />
                {touched.phone && errors.phone && <ErrorText>{errors.phone}</ErrorText>}
              </Col>
              <Col md={6}>
                <CustomSelect
                  name="insurance"
                  value={values.insurance}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <option value="">Type of insurance</option>
                  <option value="life">Life Insurance</option>
                  <option value="health">Health Insurance</option>
                  <option value="auto">Auto Insurance</option>
                </CustomSelect>
                {touched.insurance && errors.insurance && <ErrorText>{errors.insurance}</ErrorText>}
              </Col>
            </Row>
            <CustomTextArea
              name="message"
              value={values.message}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="Message..."
            />
            {touched.message && errors.message && <ErrorText>{errors.message}</ErrorText>}

            <CustomButton type="submit">
              Submit
            </CustomButton>
          </Form>
        </FormContainer>
      )}
    </Formik>
  );
};

export default CallBackForm;
