import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Link } from "react-router-dom"; // Import Link from react-router-dom
import { constants } from "../../utils/constants";
import {
  FaPhoneAlt,
  FaClock,
  FaEnvelope,
  FaMapMarkerAlt,
} from "react-icons/fa";

// Styled Components
const FooterSection = styled.footer`
  background-color: #e0f7fa;
  padding: 40px 0;
  color: #333;
`;

const Logo = styled.img`
  height: 60px;
`;

const FooterHeading = styled.h5`
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
  line-height: 20px;
  letter-spacing: -0.03em;
  text-align: left;
`;

const FooterText = styled.p`
  font-size: 14px;
  line-height: 1.5;
  margin-bottom: 20px;
  text-align: left;
`;

const ContactDetails = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 14px;

  svg {
    margin-right: 10px;
    color: #ef5226;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;

// Shared icon style for all contact details
const IconStyle = styled.span`
  svg {
    font-size: 20px;
  }
`;

const FooterBottom = styled.div`
  text-align: center;
  padding: 20px 0;
  border-top: 1px solid #ccc;
  margin-top: 30px;
  font-size: 14px;

  a {
    color: inherit;
    text-decoration: none;
    margin: 0 10px;
  }
`;

const FooterLinkList = styled.ul`
  list-style: none;
  padding: 0;

  li {
    margin-bottom: 10px;
    font-size: 16px;
    font-weight: 500;
    line-height: 25.6px;
    letter-spacing: -0.03em;
    text-align: left;

    a {
      color: inherit;
      text-decoration: none;
      font-size: 14px;

      &:hover {
        text-decoration: none;
      }
    }
  }
`;

const Footer = () => {
  return (
    <FooterSection>
      <Container>
        <Row>
          {/* Logo and Description */}
          <Col md={4} sm={12}>
            <Logo src={constants.logo} alt="Legacy Insurance Brokers" />
            <FooterText>
              Enhancing your coverage confidence means providing tailored
              insurance solutions that address your specific needs, ensuring
              comprehensive protection and financial security, and delivering
              peace of mind through expert guidance and support.
            </FooterText>
            <ContactDetails>
              <FaPhoneAlt />
              +91 8919439603
            </ContactDetails>
          </Col>

          {/* Company Links */}
          <Col md={2} sm={6}>
            <FooterHeading>Company</FooterHeading>
            <FooterLinkList>
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link
                  to="/aboutus"
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                >
                  About Us
                </Link>
              </li>
              <li>
                <Link
                  to="/services"
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                >
                  Services
                </Link>
              </li>
              <li>
                <Link
                  to="/ContactUs"
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                >
                  Contact Us
                </Link>{" "}
              </li>
            </FooterLinkList>
          </Col>

          {/* Insurance Links */}
          <Col md={2} sm={6}>
            <FooterHeading>Insurance</FooterHeading>
            <FooterLinkList>
              <li>
                <a href="/car">Car</a>
              </li>
              <li>
                <a href="/bike">Bike</a>
              </li>
              <li>
                <a href="/health">Health</a>
              </li>
              <li>
                <a href="/life">Life</a>
              </li>
            </FooterLinkList>
          </Col>

          {/* Work Hours & Contact */}
          <Col md={4} sm={12}>
            <FooterHeading>Work Hours</FooterHeading>
            <ContactDetails>
              <IconStyle>
                <FaClock />
              </IconStyle>
              9:30 AM - 9 PM, Monday – Saturday
            </ContactDetails>
            <ContactDetails>
              <IconStyle>
                <FaEnvelope />
              </IconStyle>
              <a href="mailto:support@lib.com">support@lib.com</a>
            </ContactDetails>
            <ContactDetails>
              <IconStyle>
                <FaMapMarkerAlt />
              </IconStyle>
              Office Unit No. 705, Jain Sadguru Capital Park, Image Gardens
              Road, VIP Hills, Madhapur, Hyderabad–500081.
            </ContactDetails>
          </Col>
        </Row>

        {/* Footer Bottom */}
        <FooterBottom>
          <span>Copyright © 2024 Legacy Insurance Brokers</span>
          <Link to="/terms">Terms and Conditions</Link> |{" "}
          <Link to="/privacy">Privacy Policy</Link>
        </FooterBottom>
      </Container>
    </FooterSection>
  );
};

export default Footer;
