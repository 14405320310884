import React, { useState } from 'react';
import { Card, Button, Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { BiSolidCarGarage } from "react-icons/bi";
import { AiOutlineSolution } from "react-icons/ai";
import { BsCurrencyRupee } from "react-icons/bs";
import { BlueButton } from './AboutUsSection';
import { useNavigate } from 'react-router-dom';
import InsuranceBannerSection from './InsuranceBannerSection';

const CarInsuranceBgContainer = styled.div`
  background-color: #024656;
  padding: 50px 0;
  font-family: DM Sans;
`;

const Title = styled.h2`
  color: white;
  font-weight: 500;
  text-align: center;
  margin: 20px 0;
`;

const CardStyled = styled(Card)`
  margin: 10px 0;
  background-color: #FDEEE9;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  text-align: center;
`;

const CardImageTitleContainer = styled.div`
  width: 100%;
`;

const CardTitle = styled(Card.Title)`
  color: black;
  font-weight: 600;
  font-size: 1.2rem;
`;

const CardBody = styled(Card.Body)`
  background-color: #FDEEE9;
  width: 100%;
  padding: 20px;

  @media (max-width: 767px) {
    height: auto;
  }
`;

const ToggleButton = styled(Button)`
  display: block;
  color: #EF5226;
  margin: 20px auto;
  background: none;
  border: none;
  font-family: 'Poppins', sans-serif;

  &:hover {
    background-color: transparent;
    color: #EF5226;
    border: none;
  }

  &:focus, &:active {
    outline: none;
    box-shadow: none;
    background-color: transparent;
    color: #EF5226;
    border: none;
  }

  &:focus-visible {
    outline: none;
    border: none;
  }
`;

const InsurancePlansSection = ({ insurance_plans }) => {
  const [visiblePlans, setVisiblePlans] = useState(4);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const navigate = useNavigate();

  const togglePlans = () => {
    setVisiblePlans(isExpanded ? 4 : insurance_plans.Cars.normal.length + insurance_plans.Cars.see_more.length);
    setIsExpanded(!isExpanded);
  };

  const handleCheckPremium = (link) => {
    console.log("Navigating to:", link); // Debugging log
    setShowBanner(true); 
    setTimeout(() => {
      navigate(link);
    }, 100); 
  };
  

  const plansToShow = [...insurance_plans.Cars.normal, ...insurance_plans.Cars.see_more].slice(0, visiblePlans);

  return (
    <CarInsuranceBgContainer>
      <Container>
        {showBanner && <InsuranceBannerSection />} {/* Render banner at the top */}
        <Title>Top Car Insurance Plans</Title>
        <Row>
          {plansToShow.map((plan) => (
            <Col md={6} lg={3} key={plan.id} className='py-3'>
              <CardStyled className='bg-white'>
                <CardImageTitleContainer className='h-50 d-flex flex-column justify-content-center align-items-center'>
                  <Card.Img variant="top" src={plan.ImageURL} alt={`${plan.InsuranceCompany} logo`} style={{ width: '80px' }} />
                  <CardTitle className='w-75 text-center' style={{ color: '#001E6C' }}>{plan.InsuranceCompany}</CardTitle>
                </CardImageTitleContainer>
                <CardBody>
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <BiSolidCarGarage style={{ color: '#EF5226', fontSize: '50px' }} />
                    <div className="px-2 text-start w-75">
                      <h1 className="fs-5" style={{ color: '#757575' }}>Cashless Garages</h1>
                      <h2>{plan["Cashless Garages"]}+</h2>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <AiOutlineSolution style={{ color: '#EF5226', fontSize: '50px' }} />
                    <div className="px-2 text-start w-75">
                      <h1 className="fs-5" style={{ color: '#757575' }}>Claims Settled:</h1>
                      <h2>{plan["Claims Settled"]}</h2>
                    </div>
                  </div>
                  <hr style={{ height: '2px', backgroundColor: '#EF5226' }} />
                  <Card.Text>Starting from <BsCurrencyRupee />{plan["Starting from"]}</Card.Text>
                  <BlueButton onClick={() => handleCheckPremium(plan.link)}>
                    {plan["Check Premium"]}
                  </BlueButton>
                </CardBody>
              </CardStyled>
            </Col>
          ))}
        </Row>
        <ToggleButton onClick={togglePlans} className='bg-none fw-bold'>
          {isExpanded ? 'See Less Plans' : 'See All Plans'}
        </ToggleButton>
      </Container>
    </CarInsuranceBgContainer>
  );
};

export default InsurancePlansSection;
