import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { FaPhoneAlt, FaMapMarkerAlt, FaEnvelope } from 'react-icons/fa';
import { Subtitle } from './AboutUsSection';
import { Title } from './ServicesSection';
import { StyledContainer, IconContainer } from '../components/GetStartedForm';
import  {contactDetails}  from '../utils/constants';  


const ContactForm = () => {
  return (
    <StyledContainer className='my-md-5'>
      <Row>
        <Col md={6} className="m-auto">
        <img src={contactDetails.imageSrc} alt="Contact Us" className="img-fluid" />
        </Col>
        <Col md={6}>
          <Subtitle>Let's Talk</Subtitle>
          <Title>Get in touch <span style={{ color: '#EF5226' }}>with us</span></Title>
          <p className='fs-5 fw-bold mt-4 mb-1'>Connect with Legacy Insurance Brokers for personalized support and expert advice on all your insurance needs. We're here to help!</p>
          <IconContainer>
            <div className="icon d-flex justify-content-center align-items-center">
              <FaPhoneAlt className='fs-3' />
            </div>
            <div className="text-container">
              <span className="label">Phone Number</span>
              <span className="details">+91 8919439603</span>
            </div>
          </IconContainer>
          <IconContainer>
            <div className="icon d-flex justify-content-center align-items-center">
              <FaMapMarkerAlt className='fs-3' />
            </div>
            <div className="text-container">
              <span className="label">Address</span>
              <span className="details">Office Unit No. 705, Jain Sadguru Capital Park Madhapur, <br />Hyderabad-500081</span>
            </div>
          </IconContainer>
          <IconContainer>
            <div className="icon d-flex justify-content-center align-items-center">
              <FaEnvelope className='fs-3' />
            </div>
            <div className="text-container">
              <span className="label">Email</span>
              <span className="details">info@lib.com</span>
            </div>
          </IconContainer>




        </Col>
      </Row>
    </StyledContainer>
  );
};

export default ContactForm;
