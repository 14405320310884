import React from 'react';
import ContactForm from "../../components/ContactUsForm"
import CallBackForm from "../../components/CallBackForm";
import FaqSection from '../../components/FaqSection';
import { constants } from '../../utils/constants';
import LandingSection from '../../components/TopBannerSection';
const ContactUs = ()  => {

    return (
      <>
        <LandingSection LandingData={constants.contact_landing}/>
      <ContactForm />
      <CallBackForm />
      <FaqSection />
      </>
)}

export default ContactUs;
