import React, { useState } from "react";
import { Card, Carousel, Col, Container, Row, Image } from "react-bootstrap";
import styled from "styled-components";
import { BsFillPlayCircleFill } from "react-icons/bs"; // Import play icon
import { constants } from "../utils/constants";
import {Title} from './ServicesSection';

// Styled Components
const GradientContainer = styled(Container)`
  background-image: url(${constants.testimony_bg_img});
  background-position: center;
  background-size: 100% 100%;
  height: auto;
  background-repeat: no-repeat;
  // padding: 2rem 0;
`;
const StyledCard = styled(Card)`
  border: none;
  background-color: transparent;
`;

const StyledCardBody = styled(Card.Body)`
  background-color: transparent !important;
  padding: 2rem;
`;

const StyledCarouselItem = styled(Carousel.Item)`
  .row {
    justify-content: center;
  }
  .text-center.text-lg-start {
    text-align: center;
    @media (min-width: 992px) {
      text-align: left;
    }
  }
`;

const CarouselWrapper = styled.div`
  position: relative;
`;

const CarouselControls = styled.div`
  display: flex;
  justify-content: start;
  margin-top: 10px;
  position: sticky;

  button {
    background: none;
    border: solid #ef5226 2px;
    width: 65px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    color: white;
    font-size: 3rem;
    cursor: pointer;
    margin: 0 10px;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
      background: none;
      border: solid #aaa 2px;
    }

    &:hover:not(:disabled) {
      color: #0056b3;
      background: #ef5226;
    }
  }
`;

const PlayIconOverlay = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 4rem;
  cursor: pointer;
  z-index: 1;
`;

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  padding-top: 56.25%; /* 16:9 aspect ratio */
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const CarouselIndicators = styled.ol`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  list-style: none;
  padding-left: 0;
  margin: 0;

  @media (max-width: 992px) {
    position: static;
    transform: rotate(-90deg) translateX(50%);
    text-align: center;
    margin: 0 20px;
  }

  li {
    width: 12px;
    height: 12px;
    background-color: #eaeaea;
    border-radius: 50%;
    margin-bottom: 10px;
    cursor: pointer;
    transition: background-color 0.3s;
    &:hover,
    &.active {
      background-color: #ff5722;
    }
  }
`;

const Testimonials = ({ testimonialsData}) => {
  const [index, setIndex] = useState(0);
  const [playingVideoIndex, setPlayingVideoIndex] = useState(null);
  const lastIndex = testimonialsData.length - 1;

  const handleSelect = (selectedIndex) => {
    if (selectedIndex < 0) {
      setIndex(lastIndex);
    } else if (selectedIndex > lastIndex) {
      setIndex(0);
    } else {
      setIndex(selectedIndex);
    }
    setPlayingVideoIndex(null); // Stop any video playing when switching items
  };

  const handlePlayVideo = (idx) => {
    setPlayingVideoIndex(idx);
  };

  return (
    <Container fluid className="text-center w-100 w-md-50 p-md-5">
      {/* <h1 className="fw-bold mb-3 mt-3">Testimonials</h1> */}
      <Title className="py-3">Testimonials</Title>
      <GradientContainer>
      <Row className="d-flex justify-content-center">
        <Col md={12}>
          <StyledCard>
            <StyledCardBody className="px-4 py-md-5">
              <CarouselWrapper className="bg-transparent">
                <Carousel
                  activeIndex={index}
                  onSelect={handleSelect}
                  indicators={false}
                  controls={false}
                  variant="dark"
                >
                  {testimonialsData.map((testimonial, idx) => (
                    <StyledCarouselItem key={idx}>
                      <Row className="d-flex justify-content-center">
                        <Col
                          md={9}
                          lg={7}
                          xl={8}
                          className="text-center text-md-start mx-auto mx-lg-0 text-white"
                          style={{ width: window.innerWidth >= 992 ? "50%" : "100%" }}
                        >
                          <p className="mb-0 pd-3 fs-2 fw-bold">
                            {testimonial.testimonial}
                          </p>
                          <Row className="pt-5">
                            <img
                              src={constants.testimonial_name_style}
                              alt="testimonial_name_style"
                              style={{ width: "150px", height: "50px" }}
                            />
                            <Col>
                              <h4 className="mb-0 fs-4 fs-md-3">{testimonial.name}</h4>
                              <p className="mb-4 fs-6 fs-md-4">{testimonial.role}</p>
                            </Col>
                          </Row>
                          <CarouselControls>
                            <button
                              onClick={() => handleSelect(index - 1)}
                              disabled={index === 0}
                            >
                              &#8249;
                            </button>
                            <button
                              onClick={() => handleSelect(index + 1)}
                              disabled={index === lastIndex}
                            >
                              &#8250;
                            </button>
                          </CarouselControls>
                        </Col>
                        <Col lg={4} className="d-flex justify-content-center order-first order-lg-0 position-relative">
                          {playingVideoIndex === idx ? (
                            <VideoContainer>
                              <iframe
                                src="https://www.youtube.com/embed/3NSTmGzXBtA"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                              ></iframe>
                            </VideoContainer>
                          ) : (
                            <>
                              <Image
                                src={testimonial.image}
                                square
                                fluid
                                width="400"
                                height="400"
                                className="shadow-1 mb-4 mb-lg-0"
                                alt={`${testimonial.name} avatar`}
                              />
                              <PlayIconOverlay onClick={() => handlePlayVideo(idx)}>
                                <BsFillPlayCircleFill />
                              </PlayIconOverlay>
                            </>
                          )}
                        </Col>
                      </Row>
                      <CarouselIndicators className="d-none d-md-block">
                        {testimonialsData.map((_, idx) => (
                          <li
                            key={idx}
                            onClick={() => handleSelect(idx)}
                            className={index === idx ? "active" : ""}
                          ></li>
                        ))}
                      </CarouselIndicators>
                      <CarouselIndicators className="d-block d-md-none">
                        {testimonialsData.map((_, idx) => (
                          <li
                            key={idx}
                            onClick={() => handleSelect(idx)}
                            className={index === idx ? "active" : ""}
                          ></li>
                        ))}
                      </CarouselIndicators>
                    </StyledCarouselItem>
                  ))}
                </Carousel>
              </CarouselWrapper>
            </StyledCardBody>
          </StyledCard>
        </Col>
      </Row>
	</GradientContainer>
    </Container>
  );
};

export default Testimonials;
