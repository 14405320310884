import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Navbar from './components/Navbar/Navbar';
// import ContactUs from './components/ContactUsForm';
import ContactUs from './pages/Contact/ContactUs';
import Footer from './components/Footer/Footer';
import Car from './pages/Car/car';
import Life from './pages/Life/Life';
import Health from './pages/Health/Health';
import { insurance_plans } from './utils/constants';
import Bike from './pages/Bike/Bike';
import InsurancePlansSection from './components/InsurancePlansSection';
import Homepage from './pages/Home/HomePage';
import '../src/pages/Bike/bike.css'
import Popup from './components/Popup';
import Services from './pages/AllServices/services';
import AboutUs from './pages/About/AboutUs';
import InsuranceBannerSection from './components/InsuranceBannerSection';
function App() {
  return (
    <>
      <BrowserRouter>
        <Navbar />
        <Popup/>
        <Routes>
          <Route path='/' element={<Homepage />}></Route>
          <Route path='/car' element={<Car />}></Route>
          <Route path='/life' element={<Life />}></Route>
          <Route path='/health' element={<Health />}></Route>
          <Route path='/bike' element={<Bike/>}></Route>
          <Route path='/contactus' element={<ContactUs />}></Route>
          <Route path='/services' element={<Services/>}></Route>
          <Route path='/aboutus' element={<AboutUs/>}></Route>
          <Route path="/future-car-insurance" element={<InsuranceBannerSection />} />
          <Route path="/sbi-car-insurance" element={<InsuranceBannerSection />} />
          <Route path="/kotak-car-insurance" element={<InsuranceBannerSection />} />
          <Route path="/national-car-insurance" element={<InsuranceBannerSection />} />
          <Route path="/new-india-car-insurance" element={<InsuranceBannerSection />} />
          <Route path = "/tata-aig-car-insurance" element={<InsuranceBannerSection />} />
          <Route path = "/united-india-car-insurance" element={<InsuranceBannerSection />} />
          <Route path = "/cholamandalam-car-insurance" element={<InsuranceBannerSection />} />
          <Route path = "/universal-sompo-car-insurance" element={<InsuranceBannerSection />} />
          <Route path = "/reliance-car-insurance" element={<InsuranceBannerSection />} />
          <Route path = "/oriental-car-insurance" element={<InsuranceBannerSection />} />
  {/* Dynamic routes for each Insurance plans */}
{Object.keys(insurance_plans).map((insurance) => {
    console.log(insurance_plans[insurance].link); // Log the links
    return (
        <Route
            key={insurance}
            path={insurance_plans[insurance].link}
            element={<InsurancePlansSection data={insurance_plans[insurance]} />}
        />
    );
})}

        </Routes>
        
        <Footer />
      </BrowserRouter>

    </>
  );
}

export default App;