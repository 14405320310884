import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { InsuranceBannerContent } from '../utils/constants';
import { StyledButton } from './GetStartedForm';

const HeroSection = styled.section`
  background-image: url(${(props) => props.bgImage}); /* Use the image passed via props */
  background-size: cover; /* Ensures the image covers the entire section */
  background-position: center; /* Centers the image */
  padding: 40px 0;
`;

const Logo = styled.img`
  max-width: 100%;
`;

const Heading = styled.h1`
  font-size: 2rem;
  font-weight: 700;
  color: #023047;
`;

const InputField = styled.input`
  background-color: white;
  border: none;
  color: black;
  padding: 12px;
  text-align: left;
  border-radius: 6px;
  font-weight: 500;
  width: 100%; /* Make it take the full width of its container */
  height: 50px;

  /* Media query for mobile devices */
  @media (max-width: 767px) {
    width: auto;
  }
`;

const CircleBg = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 350px;
  height: 350px;
  background-color: #0277bd;
  border-radius: 50%;
  z-index: -1;
`;

const CircleContainer = styled.div`
  position: relative;
  width: 300px;
  height: 300px;
  margin: auto;
  border-radius: 50%;
  background: linear-gradient(135deg, rgba(0, 206, 209, 1) 0%, rgba(255, 99, 71, 1) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
`;

const InfoCard = styled.div`
  position: absolute;
  background: #fff;
  padding: 6px 8px;
  border-radius: 10px;
  text-align: center;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  width: 130px;

  &.top-left {
    top: 10%;
    left: -10%;
  }
  &.top-right {
    top: 10%;
    right: -10%;
  }
  &.bottom {
    bottom: -10%;
  }

  .icon {
    font-size: 2rem;
    color: #f06060;
    margin-bottom: 0.5rem;
  }
`;

const Subtitle = styled.p`
  font-size: 1.2rem;
  color: #004e66;
`;

const InsuranceBannerSection = () => {
  const { logo, heading, inputPlaceholder, buttonText, backgroundImage } = InsuranceBannerContent;

  return (
    <HeroSection bgImage={backgroundImage}>
      <Container>
        <Row className="align-items-center">
          <Col md={6}>
            <Logo src={logo} alt="National Insurance" />
            <Heading>{heading}</Heading>
            <Row className="mt-3">
              <Col md={8} className="pe-1"> {/* Adjust margin on the right for spacing */}
                <InputField placeholder={inputPlaceholder} />
              </Col>
              <Col md={4}> {/* Button column */}
                <StyledButton>{buttonText}</StyledButton>
              </Col>
            </Row>
          </Col>
          <Col md={6} className="position-relative">
            <CircleBg />
            <Row>
              <CircleContainer>
                {InsuranceBannerContent.infoBoxes.map((box, index) => {
                  let positionClass = '';
                  if (index === 0) positionClass = 'top-left';
                  if (index === 1) positionClass = 'top-right';
                  if (index === 2) positionClass = 'bottom';

                  return (
                    <InfoCard className={positionClass} key={index}>
                      <div className="icon">{box.icon}</div>
                      <Subtitle>{box.title}</Subtitle>
                      <p>{box.value}</p>
                    </InfoCard>
                  );
                })}
              </CircleContainer>
            </Row>
          </Col>
        </Row>
      </Container>
    </HeroSection>
  );
};

export default InsuranceBannerSection;
