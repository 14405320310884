import React from 'react';
import { Card, Button, Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import {Title} from './ServicesSection';

const ServiceIcon = styled.div`
  font-size: 40px;
  width: 80px;
  color: white;
  padding: 15px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
  background-color: #024656;

  @media screen and (max-width: 992px) {
    font-size: 30px;
    width: 60px;
    padding: 10px;
  }
`;

// Styled component for Card
const StyledCard = styled(Card)`
  text-align: center;
  padding: 30px 20px;
  background-color: #E6F7F9;
  border: none;
  border-radius: 12px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 30px;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
  }

  .card-title {
    font-size: 1.25rem;
    font-weight: bold;
    color: #05445E;
    margin-bottom: 15px;
  }

  .card-text {
    color: #7A869A;
    margin-bottom: 20px;
  }

  .btn {
    background-color: #05445E;
    border: none;
    border-radius: 50px;
    padding: 10px 20px;
    font-size: 0.875rem;

    &:hover {
      background-color: #042C43;
    }
  }

  img {
    margin-bottom: 20px;
    width: 60px;
    height: 60px;
  }
`;

const ServicesList = ({ services }) => (
  <Container className='text-center'>
          <Title className="py-3">Our Services</Title>
    <Row className="justify-content-center mt-3">
      {services.map((service) => (
        <Col xs={12} sm={6} md={6} lg={3} key={service.id}>
          <StyledCard>
            <ServiceIcon>{service.icon}</ServiceIcon>
            <Card.Body>
              <Card.Title>{service.title}</Card.Title>
              <Card.Text>{service.description}</Card.Text>
              <Button>Buy Now</Button>
            </Card.Body>
          </StyledCard>
        </Col>
      ))}
    </Row>
  </Container>
);

export default ServicesList;
